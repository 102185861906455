import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Form, Tabs } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { NoticeCard } from 'components/system';
import FooterBox from 'components/FooterBox';
import { scrollToFirstErrorOption } from 'lib/consts';
import ManuCompanyForm from './ManuCompanyForm';
import ManuSuperUserForm from './ManuSuperUserForm';
import useManuRegisterForm from './hooks/useManuRegisterForm';
import path from 'lib/path';

interface ManuRegisterEditFormProps {
  handleChangeTab?: (tab: 'company' | 'user') => void;
}

const ManuRegisterEditForm = ({ handleChangeTab }: ManuRegisterEditFormProps) => {
  const navigate = useNavigate();
  const { user, company, factories, submitLoading } = useSelector(
    ({ auth, company, loading }) => ({
      user: auth.user,
      company: company.company,
      factories: company.factories,
      submitLoading: loading['auth/MANU_REGISTER_COMPANY'] || loading['auth/MANU_UPDATE_COMPANY'],
    }),
    shallowEqual,
  );

  const { form, searchValue, factorycGMP, handleSelectSearch, handleInitialData, handleEditManu } =
    useManuRegisterForm({
      initialData: useMemo(() => ({ user, company, factories }), [user, company, factories]),
      handleChangeTab,
    });

  return (
    <Wrapper>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={handleEditManu}
      >
        <StyledTabs
          defaultActiveKey={'company'}
          items={[
            {
              key: 'company',
              label: '회사 정보',
              children: (
                <>
                  <NoticeCard title="안내사항" style={{ marginTop: 0, marginBottom: 32 }}>
                    <ul>
                      <li>사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이 필요합니다.</li>
                      <li>
                        정보 수정이나 공장소재지 추가를 원하실 경우 전화나 하단의 ‘이용 문의’로
                        문의해 주세요.
                      </li>
                    </ul>
                  </NoticeCard>
                  <TabItemWrapper>
                    <ManuCompanyForm
                      isEdit
                      form={form}
                      searchValue={searchValue}
                      factorycGMP={factorycGMP}
                      handleSelectSearch={handleSelectSearch}
                    />
                    <FooterBox>
                      <Button
                        loading={submitLoading}
                        htmlType="button"
                        onClick={() => navigate(path.userInquiry)}
                      >
                        수정 문의
                      </Button>
                    </FooterBox>
                  </TabItemWrapper>
                </>
              ),
            },
            {
              key: 'user',
              label: '슈퍼유저 정보',
              children: (
                <>
                  <NoticeCard title="안내사항" style={{ marginTop: 0 }}>
                    <ul>
                      <li>
                        슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능하며, 모든 영역에서 최고
                        권한을 가진 계정입니다.
                      </li>
                      <li>슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.</li>
                    </ul>
                  </NoticeCard>
                  <TabItemWrapper>
                    <ManuSuperUserForm isEdit form={form} />
                    <FooterBox>
                      <Button type="primary" loading={submitLoading} htmlType="submit">
                        수정
                      </Button>
                    </FooterBox>
                  </TabItemWrapper>
                </>
              ),
            },
          ]}
          onChange={handleInitialData}
        />
      </Form>
    </Wrapper>
  );
};

export default ManuRegisterEditForm;

const Wrapper = styled.div`
  max-width: 1040px;
`;

const TabItemWrapper = styled.div`
  max-width: 688px;
  margin: 0 auto;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 36px;
    cursor: pointer;

    .ant-tabs-tab {
      width: 160px;
      justify-content: center;
      margin-right: 0;

      .ant-tabs-tab-btn {
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: 400;
      }
    }
  }
  .ant-tabs-ink-bar {
    height: 4px !important;
  }
`;
