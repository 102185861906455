import { useForm } from 'antd/lib/form/Form';
import _, { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import ArtworkStep1 from 'components/brand/artworkScreening/step1/ArtworkStep1';
import ArtworkStep2 from 'components/brand/artworkScreening/step2/ArtworkStep2';
import ArtworkStep4 from 'components/brand/artworkScreening/step4/ArtworkStep4';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { ArtworkParamItem, OriginArtworkItem } from 'types/brand/product/artworkScreening';
import palette from 'lib/styles/palette';
import { useFormulaArtworkScreening } from 'service/brand/artworkScreening/artworkScreening';
import { ScreeningStatus } from 'types/brand/artworkScreening/artworkScreening';
import FullLoading from 'components/FullLoading';
import { ArtworkScreeningFormData } from 'components/brand/artworkScreening/step1/ArtworkScreeningTemplate';
import ArtworkStep5 from 'components/brand/artworkScreening/step5/ArtworkStep5';
import path from 'lib/path';
import { UserType } from 'types/auth';
import client from 'lib/api/client';
import OriginArtworkStep3 from 'components/brand/artworkScreening/step3/OriginArtworkStep3';
import ArtworkStep3 from 'components/brand/artworkScreening/step3/ArtworkStep3';
import OriginArtworkStep4 from 'components/brand/artworkScreening/step4/OriginArtworkStep4';
import PerformanceMeasurementList from 'components/brand/artworkScreening/PerformanceMeasurementList';

const ScreeningNavigator = ({
  steps,
  activeStep,
}: {
  steps: { id: number; title: string }[];
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <NavigatorContainer>
      {steps.map(({ id, title }) => {
        const isActive = activeStep === id;
        const isCheckedStep = activeStep > id;
        return (
          <StepDiv key={id} align="center" gap={8} active={isActive}>
            {!isActive && isCheckedStep && <Icon name="checkCircleHover" size={16} />}
            {!isCheckedStep && (
              <NumIcon active={isActive}>{activeStep > steps.length ? id - 1 : id}</NumIcon>
            )}
            <StepTitle active={isActive}>{title}</StepTitle>
          </StepDiv>
        );
      })}
    </NavigatorContainer>
  );
};

const ArtworkScreeningPage = ({
  isAdmin = false,
  isPerformanceMeasurement = false,
  isMandatoryScreening = false,
  measurementType,
}: {
  isAdmin?: boolean;
  isPerformanceMeasurement?: boolean;
  isMandatoryScreening?: boolean;
  measurementType?: string | null;
}) => {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const adminToken = queryParams.get('adminToken');
  const adminRefreshToken = queryParams.get('adminRefreshToken');

  const navigate = useNavigate();
  const { auth } = useSelector(({ auth }: any) => ({ auth }));
  const [activeStep, setActiveStep] = useState<number>(1);
  const [artworkFile, setArtworkFile] = useState<Record<string, File | ArtworkParamItem | null>>(
    {},
  );
  const [performaceArtworkFiile, setPerformaceArtworkFiile] = useState<File | null>(null);
  const [existedPerformaceArtworkFile, setExistePerformacedArtworkFile] =
    useState<OriginArtworkItem | null>(null);
  const [existedArtworkFile, setExistedArtworkFile] = useState<
    Record<string, ArtworkParamItem | null>
  >({});
  const [, setStep2DisabledMode] = useState(true);
  const [screeningForm] = useForm<ArtworkScreeningFormData>();
  const [screeningId, setScreeningId] = useState<number>();
  const [selectedPerformanceCountryCodes, setSelectedPerformanceCountryCodes] = useState<string[]>(
    [],
  );
  const {
    uploadFormulaArtworkScreeningJson,
    formulaScreeningResult,
    formulaArtworkScreeningLoading,
    formulaScreeningArtworkFile,
    formulaScreeningStatus,
    getFormulaScreeningStatusLoading,
    originformulaScreeningArtworkFile,
  } = useFormulaArtworkScreening({ formulaScreeningId: screeningId });
  const isPerformanceMeasurementEnv = isAdmin && isPerformanceMeasurement;

  const params = useParams<{
    screeningId: string;
  }>();

  useEffect(() => {
    if (
      !isAdmin &&
      auth.user?.userType !== UserType.MANUFACTURE &&
      auth.user?.userType !== UserType.BRAND
    ) {
      message.error('올바르지 않은 접근입니다.');
      navigate(`${path.logout}?reason=expire`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isAdmin) {
      const showError = () => {
        message.error('올바르지 않은 접근입니다.');
        navigate(`${path.logout}?reason=expire`, { replace: true });
      };
      if (adminToken) {
        sessionStorage.setItem('auth.token', adminToken);
        client.defaults.headers.common['Authorization'] = `Bearer ${adminToken}`;
      } else {
        showError();
      }
    }
  }, [isAdmin, adminToken]);

  useEffect(() => {
    if (measurementType === 'A' && !params.screeningId) {
      setActiveStep(1);
    }
  }, [measurementType]);

  useEffect(() => {
    if (measurementType === 'A') return;
    if (params.screeningId) {
      setScreeningId(Number(params.screeningId));
      originformulaScreeningArtworkFile(
        {
          formulaScreeningId: Number(params.screeningId),
        },
        {
          onSuccess: (res) => {
            setExistePerformacedArtworkFile(res.data.result);
          },
        },
      );
      if (isMandatoryScreening) {
        formulaScreeningArtworkFile(
          { formulaScreeningId: Number(params.screeningId) },
          {
            onSuccess: (res) => {
              const { artworks } = res.data.result;

              artworks.forEach((item) => {
                setArtworkFile((prev) => ({ ...prev, [item.packingType]: item }));
                setExistedArtworkFile((prev) => {
                  return {
                    ...prev,
                    [item.packingType]: item,
                  };
                });
              });
            },
          },
        );
      }
    }
  }, [params, isMandatoryScreening, measurementType]);

  useEffect(() => {
    if (formulaScreeningResult?.formulaScreeningId) {
      setScreeningId(formulaScreeningResult.formulaScreeningId);

      screeningForm.setFieldsValue({
        ingredients: formulaScreeningResult.list.map(
          ({ ingredientName, ingredientWt, allergenRawList }) => ({
            name: ingredientName,
            wt: ingredientWt,
            allergens: allergenRawList.map(
              ({ ingredientAllergenId, ingredientInciName, allergenWt }) => ({
                ingredientAllergenId,
                allergenInciName: ingredientInciName,
                allergenWt: allergenWt,
              }),
            ),
          }),
        ),
      });
    }
  }, [formulaScreeningResult]);

  useEffect(() => {
    if (
      params.screeningId &&
      measurementType !== 'A' &&
      (formulaScreeningStatus?.status === ScreeningStatus.ANALYZE ||
        formulaScreeningStatus?.status === ScreeningStatus.COMPLETE)
    ) {
      setActiveStep(4);
    }
  }, [formulaScreeningStatus]);

  useEffect(() => {
    const layout = document.getElementById('app-content-block');
    if (layout) {
      layout.style.paddingBottom = '0';
    }

    return () => {
      if (layout) {
        layout.style.paddingBottom = '80px';
      }
    };
  }, []);

  const steps = [
    {
      id: 1,
      title: '전성분표 입력',
    },
    {
      id: 2,
      title: '전성분표 분석',
    },
    {
      id: 3,
      title: 'Artwork 업로드',
    },
    ...((isPerformanceMeasurementEnv && measurementType !== 'A') || !isPerformanceMeasurement
      ? [
          {
            id: 4,
            title: '스크리닝 분석 결과',
          },
        ]
      : []),
    ...(isPerformanceMeasurementEnv
      ? [
          {
            id: 5,
            title: '최종 전성분 정보',
          },
        ]
      : []),
  ];

  const handleFormulaScreeningForArtwork = () => {
    const ingredients: ArtworkScreeningFormData['ingredients'] =
      screeningForm.getFieldValue('ingredients');

    const filteredIngredients = ingredients
      ?.filter(({ name }) => name && name !== null && name !== '')
      .map((item) => ({
        ...item,
        allergens: item.allergens.map(({ allergenInciName, allergenWt, ingredientAllergenId }) => ({
          allergenInciName,
          allergenWt,
          ingredientAllergenId,
        })),
      }));

    const canSubmit = !isEqual(
      filteredIngredients,
      formulaScreeningResult?.list.map(({ ingredientName, ingredientWt, allergenRawList }) => ({
        name: ingredientName,
        wt: ingredientWt,
        allergens: allergenRawList.map(
          ({ ingredientAllergenId, ingredientInciName, allergenWt }) => ({
            allergenInciName: ingredientInciName,
            allergenWt,
            ingredientAllergenId,
          }),
        ),
      })),
    );

    if (!canSubmit) return setActiveStep(2);

    uploadFormulaArtworkScreeningJson(
      {
        ingredients: filteredIngredients.map((item) => ({
          name: item.name.trimEnd(),
          ingredientWt: item.wt,
          allergenList: item.allergens.map(
            ({ ingredientAllergenId, allergenInciName, allergenWt }) => ({
              ingredientAllergenId,
              name: allergenInciName,
              allergenWt,
            }),
          ),
        })),
      },
      {
        onSuccess: () => {
          setActiveStep(2);
        },
      },
    );
  };

  if (getFormulaScreeningStatusLoading) return <FullLoading />;

  const handleGoStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Container>
      {measurementType === 'A' && activeStep === 1 && (
        <PerformanceMeasurementList token={adminToken} handleGoStep={handleGoStep} />
      )}
      {((measurementType === 'A' && activeStep !== 1) ||
        measurementType === 'B' ||
        !measurementType ||
        !isAdmin) && (
        <ScreeningNavigator steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      )}

      {activeStep === 1 && measurementType !== 'A' ? (
        <ArtworkStep1
          form={screeningForm}
          setStep2DisabledMode={setStep2DisabledMode}
          handleFormulaScreeningForArtwork={handleFormulaScreeningForArtwork}
          formulaArtworkScreeningLoading={formulaArtworkScreeningLoading}
        />
      ) : activeStep === 2 ? (
        <ArtworkStep2
          isAdmin={isAdmin}
          isPerformanceMeasurement={isPerformanceMeasurement}
          setStep={setActiveStep}
          formulaScreeningResult={formulaScreeningResult}
          adminToken={adminToken}
          adminRefreshToken={adminRefreshToken}
        />
      ) : activeStep === 3 ? (
        isPerformanceMeasurementEnv ? (
          <OriginArtworkStep3
            adminToken={adminToken}
            measurementType={measurementType}
            isAdmin={isAdmin}
            isPerformanceMeasurement={isPerformanceMeasurement}
            artworkFile={performaceArtworkFiile}
            setArtworkFile={setPerformaceArtworkFiile}
            setStep={setActiveStep}
            screeningId={screeningId}
            existedArtworkFile={existedPerformaceArtworkFile}
            setExistedArtworkFile={setExistePerformacedArtworkFile}
          />
        ) : (
          <ArtworkStep3
            isAdmin={isAdmin}
            artworkFile={artworkFile}
            setArtworkFile={setArtworkFile}
            setStep={setActiveStep}
            screeningId={screeningId}
            existedArtworkFile={existedArtworkFile}
            setExistedArtworkFile={setExistedArtworkFile}
          />
        )
      ) : activeStep === 4 ? (
        activeStep === 4 &&
        screeningId &&
        (isPerformanceMeasurementEnv ? (
          <OriginArtworkStep4
            adminToken={adminToken}
            measurementType={measurementType}
            formulaScreeningId={screeningId}
            setStep={setActiveStep}
            selectedPerformanceCountryCodes={selectedPerformanceCountryCodes}
            setSelectedPerformanceCountryCodes={setSelectedPerformanceCountryCodes}
          />
        ) : (
          <ArtworkStep4
            formulaScreeningId={screeningId}
            setStep={setActiveStep}
            isAdmin={isAdmin}
            adminToken={adminToken}
            adminRefreshToken={adminRefreshToken}
          />
        ))
      ) : (
        activeStep === 5 && (
          <>
            {measurementType === 'A' || screeningId ? (
              <ArtworkStep5
                setStep={setActiveStep}
                adminToken={adminToken}
                formulaScreeningId={
                  measurementType === 'A' ? Number(params.screeningId) : screeningId
                }
                selectedPerformanceCountryCodes={selectedPerformanceCountryCodes}
                measurementType={measurementType}
              />
            ) : null}
          </>
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: -58px !important;
  max-width: 100vw !important;
`;

const NavigatorContainer = styled.div`
  width: 216px;
  border-right: 1px solid ${palette.GRAY30};
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  padding: 136px 16px;
`;

const StepDiv = styled(Flex)<{ active: boolean }>`
  padding: 18px 16px;
  border-radius: 4px;
  ${({ active }) => css`
    background-color: ${active ? `${palette.SLATE_GRAY10}` : `transparent`};
  `};
`;

const NumIcon = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) =>
    active ? `${palette.PRIMARY50}` : `${palette.SLATE_GRAY50}`};
  border-radius: 100px;
  font-size: 12px;
  color: white;
`;

const StepTitle = styled(Typography.Text)<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? `500` : `400`)};
  text-align: center;
  color: ${({ active }) => (active ? `${palette.SLATE_GRAY70}` : `${palette.SLATE_GRAY50}`)};
`;

export default ArtworkScreeningPage;
