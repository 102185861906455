import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { useArtworkScreeningAllergens } from 'service/brand/artworkScreening/artworkScreening';
import { ArtworkScreeningAllergenWithWt } from 'types/brand/artworkScreening/artworkScreening';
import Icon from 'components/ui/Icon/Icon';
import { sortEnAndKoOptionsByKeyword } from 'lib/common';

interface ArtworkScreeningAllergensForm {
  allergens: ArtworkScreeningAllergenWithWt[];
}

export interface AllergenInputModalProps {
  onClose?: () => void;
  onOk: (formValues: ArtworkScreeningAllergensForm) => void;
  allergens: ArtworkScreeningAllergenWithWt[];
}

const AllergenInputModal = ({ allergens, onOk, onClose }: AllergenInputModalProps) => {
  const [form] = Form.useForm<ArtworkScreeningAllergensForm>();
  const [keyword, setKeyword] = useState('');
  const { artworkScreeningAllergens, getArtworkScreeningAllergensLoading } =
    useArtworkScreeningAllergens();

  const handleSubmit = (formValues: ArtworkScreeningAllergensForm) => {
    onOk(formValues);
    onClose?.();
  };

  useEffect(() => {
    const allergensMap = allergens.reduce((acc, curr) => {
      acc[curr.ingredientAllergenId] = curr;
      return acc;
    }, {} as Record<number, ArtworkScreeningAllergenWithWt>);

    form.setFieldsValue({
      allergens: allergens.map((item) => allergensMap[item.ingredientAllergenId]),
    });
  }, [artworkScreeningAllergens]);

  const handleDeleteAllergen = (idx: number) => {
    const newAllergens = [...form.getFieldValue('allergens')].filter((_, index) => idx !== index);

    form.setFieldsValue({
      allergens: newAllergens,
    });
  };

  const highlightText = (text: string, keyword: string, disabled: boolean) => {
    if (!keyword) return text;

    const regex = new RegExp(`(${keyword})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === keyword.toLowerCase()) {
        return (
          <span key={index} style={{ color: disabled ? palette.GRAY50 : palette.PRIMARY50 }}>
            {part}
          </span>
        );
      } else {
        return (
          <span key={index} style={{ color: disabled ? palette.GRAY50 : palette.GRAY90 }}>
            {part}
          </span>
        );
      }
    });
  };

  return (
    <Spin spinning={getArtworkScreeningAllergensLoading}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.List name="allergens" initialValue={[]}>
          {(fields, { add }) => {
            return (
              <>
                <Form.Item shouldUpdate={(prev, next) => prev !== next}>
                  {({ getFieldValue }) => {
                    const allergenOptions = artworkScreeningAllergens.map(
                      ({ ingredientAllergenId, allergenNameKo, allergenInciName }) => {
                        const isAlreadySelected = getFieldValue('allergens')?.some(
                          ({ ingredientAllergenId: id }: { ingredientAllergenId: number }) =>
                            ingredientAllergenId === id,
                        );
                        return {
                          label: (
                            <Flex
                              gap={2}
                              dir="column"
                              style={{ cursor: isAlreadySelected ? 'not-allowed' : 'pointer' }}
                            >
                              <Typography.Text
                                type="BODY_2"
                                color={isAlreadySelected ? 'GRAY50' : 'GRAY90'}
                              >
                                {highlightText(allergenInciName, keyword, isAlreadySelected)}
                              </Typography.Text>
                              <Typography.Text
                                type="SMALL"
                                color={isAlreadySelected ? 'GRAY50' : 'GRAY70'}
                              >
                                {highlightText(allergenNameKo, keyword, isAlreadySelected)}
                              </Typography.Text>
                            </Flex>
                          ),
                          customLabel: [allergenInciName, allergenNameKo],
                          value: ingredientAllergenId,
                          disabled: isAlreadySelected,
                        };
                      },
                    );

                    const sortedOptions = sortEnAndKoOptionsByKeyword(
                      [...allergenOptions],
                      keyword,
                    );

                    return (
                      <>
                        <Flex
                          style={{ margin: '0 auto 24px', width: '480px' }}
                          gap={8}
                          dir="column"
                        >
                          <Typography.Text type="TITLE_2" color="GRAY90">
                            알레르기 유발성분 선택
                          </Typography.Text>
                          <Select
                            placeholder="검색 또는 선택"
                            options={sortedOptions}
                            style={{ width: '100%', textAlign: 'start' }}
                            onSelect={(val?: number | null) => {
                              const allergenInfo = artworkScreeningAllergens.find(
                                ({ ingredientAllergenId }) => ingredientAllergenId === val,
                              );
                              setKeyword('');

                              add({
                                ingredientAllergenId: val,
                                allergenInciName: allergenInfo?.allergenInciName,
                                allergenNameKo: allergenInfo?.allergenNameKo,
                                allergenWt: '',
                              });
                            }}
                            showSearch
                            onSearch={(value) => setKeyword(value)}
                            filterOption={(keyword, option) => {
                              return (
                                (option?.customLabel &&
                                  option.customLabel
                                    .map((label) => label.toLowerCase())
                                    .some((value) => value.includes(keyword.toLowerCase()))) ||
                                false
                              );
                            }}
                            value={null}
                          />
                        </Flex>
                        <div style={{ height: 360, overflowY: 'auto' }}>
                          <StyledTable>
                            <thead>
                              <tr>
                                <th style={{ width: 44, background: 'white' }}></th>
                                <th style={{ width: 56, textAlign: 'center' }}>No.</th>
                                <th style={{ width: 384 }}>{`INCI Name\n한글 성분명`}</th>
                                <th style={{ width: 176 }}>{`향료에서의 함량비율(%)`}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map((field, idx) => (
                                <React.Fragment key={field.key}>
                                  <tr key={field.key}>
                                    <td style={{ width: 44 }}>
                                      <MinusIcon
                                        name="controlMinus"
                                        size={24}
                                        onClick={() => handleDeleteAllergen(idx)}
                                      />
                                    </td>
                                    <td style={{ width: 56, textAlign: 'center' }}>{idx + 1}</td>
                                    <td style={{ width: 384 }}>
                                      <Typography.Text type="BODY_2">
                                        {getFieldValue('allergens')?.[field.key]?.allergenInciName}
                                      </Typography.Text>
                                      <Typography.Text type="BODY_2" gutter={{ top: 2 }}>
                                        {getFieldValue('allergens')?.[field.key]?.allergenNameKo ||
                                          '-'}
                                      </Typography.Text>
                                    </td>
                                    <td style={{ width: 176 }}>
                                      <Form.Item
                                        name={[field.name, 'allergenWt']}
                                        rules={[
                                          {
                                            validator: async (_, value) => {
                                              if (!value) return Promise.resolve();

                                              if (isNaN(value)) {
                                                return Promise.reject('0~100 숫자만 입력 가능');
                                              }

                                              const numValue = Number(value);
                                              if (numValue < 0 || numValue > 100) {
                                                return Promise.reject('0~100 숫자만 입력 가능');
                                              }

                                              if (!/^-?\d+(\.\d{1,25})?$/g.test(value)) {
                                                return Promise.reject(
                                                  '소수점 최대 25자리까지 가능',
                                                );
                                              }

                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                        style={{ marginBottom: 0 }}
                                      >
                                        <Input placeholder="% 입력" />
                                      </Form.Item>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </StyledTable>
                        </div>
                      </>
                    );
                  }}
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Flex justify="center" align="center" gap={8} gutter={{ top: 24 }}>
          <Button key="back" onClick={onClose} style={{ width: 84 }}>
            건너뛰기
          </Button>
          <Button htmlType="submit" type="primary" style={{ width: 84 }}>
            입력 완료
          </Button>
        </Flex>
      </Form>
    </Spin>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  max-width: 640px;

  thead tr {
    background-color: ${palette.GRAY10};
    height: 56px;

    th {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: ${palette.SLATE_GRAY70};
      border-bottom: 1px solid ${palette.GRAY40};
      border-top: 2px solid ${palette.PRIMARY50};

      &:first-of-type {
        padding-left: 16px;
        border-bottom: none !important;
        border-top: none !important;
      }
      &:last-of-type {
        padding-right: 16px;
      }
    }
  }

  tbody tr {
    height: 64px;
    max-height: 64px !important;
    max-width: 640px;

    td {
      padding: 10px;
      border-bottom: 1px solid ${palette.GRAY40};

      &:first-of-type {
        padding-left: 16px;
        border-bottom: none !important;
      }
      &:last-of-type {
        padding-right: 16px;
      }
    }
  }

  .ant-form-item .ant-form-item-explain {
    height: 0;
    min-height: 0;
    transform: translateY(-2px);
  }
`;

const MinusIcon = styled(Icon)``;

export default AllergenInputModal;
