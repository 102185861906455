import { Link } from 'react-router-dom';
import { Button, Col, Flex, Form, Row } from 'antd';
import styled from 'styled-components';

import Typography from 'components/system/general/Typography';
import FooterBox from 'components/FooterBox';
import Icon from 'components/ui/Icon/Icon';
import { scrollToFirstErrorOption } from 'lib/consts';
import path from 'lib/path';
import ManuCompanyForm from './ManuCompanyForm';
import ManuSuperUserForm from './ManuSuperUserForm';
import useManuRegisterForm from './hooks/useManuRegisterForm';

interface ManuRegisterCreateFormProps {
  currentTab: 'company' | 'user';
  handleChangeTab: (tab: 'company' | 'user') => void;
}

export interface CGMP {
  factoryKey: number;
  isCGMP: boolean;
  ISOExpiredDate: string;
  file: File | null | { url: string; name: string };
  uploadedFile: {
    url: string;
    name: string;
  } | null;
}

const ManuRegisterCreateForm = ({ currentTab, handleChangeTab }: ManuRegisterCreateFormProps) => {
  const {
    form,
    searchValue,
    submitLoading,
    factorycGMP,
    allManufacturers,
    handleSelectSearch,
    handleClickNext,
    handleClickPrev,
    handleCreateManu,
    handleUploadCgmpFile,
    handleChangeDocumentCode,
    handleChangeExpiryDate,
  } = useManuRegisterForm({ handleChangeTab });

  return (
    <Container>
      <Form
        form={form}
        colon={false}
        layout="vertical"
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={handleCreateManu}
      >
        {currentTab === 'company' && (
          <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
            1. 회사 정보
          </Typography.Text>
        )}
        <ManuCompanyForm
          isVisible={currentTab === 'company'}
          form={form}
          isEdit={false}
          searchValue={searchValue}
          factorycGMP={factorycGMP}
          allManufacturers={allManufacturers}
          handleSelectSearch={handleSelectSearch}
          handleUploadCgmpFile={handleUploadCgmpFile}
          handleChangeDocumentCode={handleChangeDocumentCode}
          handleChangeExpiryDate={handleChangeExpiryDate}
        />
        {currentTab === 'user' && (
          <>
            <Row align="middle" gutter={8}>
              <Col>
                <Typography.Text type="TITLE_1">
                  <Icon
                    name="back"
                    size={16}
                    style={{ display: 'inline-block', marginRight: 16, cursor: 'pointer' }}
                    onClick={handleClickPrev}
                  />
                  2. 슈퍼유저 정보
                </Typography.Text>
              </Col>
            </Row>
            <ManuSuperUserForm isVisible={currentTab === 'user'} form={form} />
          </>
        )}
        <FooterBox>
          {currentTab === 'company' && (
            <Flex gap={16}>
              <Link to={path.login}>
                <Button htmlType="button">취소</Button>
              </Link>
              <Button type="primary" onClick={handleClickNext}>
                다음
              </Button>
            </Flex>
          )}
          {currentTab === 'user' && (
            <>
              <Button onClick={handleClickPrev}>뒤로</Button>
              <Button htmlType="submit" type="primary" loading={submitLoading}>
                회원가입
              </Button>
            </>
          )}
        </FooterBox>
      </Form>
    </Container>
  );
};

export default ManuRegisterCreateForm;

const Container = styled.div`
  max-width: 688px;
  margin: 0 auto;
  padding: 40px 0;
`;
