import { useEffect } from 'react';

const usePasteImage = ({
  handleClickedUploadArea,
  handlePasteSuccess,
}: {
  handleClickedUploadArea: (type: string, boolean: boolean) => void;
  handlePasteSuccess: (type: string, file: File) => void;
}) => {
  useEffect(() => {
    let clickedType = '';

    const handleClick = (e: MouseEvent) => {
      const clickedElement = e.target as HTMLElement;

      if (!(clickedElement instanceof HTMLElement)) {
        return;
      }

      if (clickedElement.className.includes('uploader')) {
        const classnames = clickedElement.className.split(' ');
        const targetType = classnames.find((name) => name.includes('uploader'));

        clickedType = targetType?.split('_')[1] || '';
        handleClickedUploadArea(clickedType, true);
      } else {
        handleClickedUploadArea(clickedType, false);
      }
    };

    const handlePaste = async () => {
      try {
        const clipboardItems = await navigator.clipboard.read();

        const firstItemType = clipboardItems[0].types.find((type) => type.startsWith('image/'));
        if (!firstItemType) return;

        const blob = await clipboardItems[0].getType(firstItemType);

        const extension = firstItemType.split('/')[1];

        const file = new File([blob], `image.${extension}`, { type: firstItemType });
        handlePasteSuccess(clickedType, file);
      } catch (error) {
        console.error('클립보드에서 이미지를 읽는 중 오류 발생:', error);
      }
    };

    document.body.addEventListener('click', handleClick);
    document.body.addEventListener('paste', handlePaste);

    return () => {
      document.body.removeEventListener('click', handleClick);
      document.body.removeEventListener('paste', handlePaste);
    };
  }, []);
};

export default usePasteImage;
