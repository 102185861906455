import { message } from 'antd';
import styled, { css } from 'styled-components';
import { useEffect } from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { ArtworkIngredient } from 'types/brand/artworkScreening/artworkScreening';
import { countryImages } from 'lib/consts';
import { ArtworkStep4TabType } from 'service/brand/artworkScreening/artworkScreening';

interface CountryListProps {
  availableCountryCodes: string[];
  formulaSelectedCountries: string[];
  mandatorySelectedCountries?: string[];
  selectedPerformanceCountryCodes?: string[];
  countryCodeToNameMap: Record<string, string>;
  setMandatorySelectedCountries?: (countryCodes: string[]) => void;
  setFormulaSelectedCountries?: (countryCodes: string[]) => void;
  setSelectedPerformanceCountryCodes?: (countryCodes: string[]) => void;
  artworkIngredients: ArtworkIngredient[];
  isAdmin?: boolean;
  isMandatoryScreening?: boolean;
  isPerformanceMeasurement?: boolean;
  isShowResult?: boolean;
  tab?: ArtworkStep4TabType;
}

const CountryList = ({
  isPerformanceMeasurement = false,
  isMandatoryScreening = false,
  availableCountryCodes,
  mandatorySelectedCountries,
  formulaSelectedCountries,
  selectedPerformanceCountryCodes,
  countryCodeToNameMap,
  setFormulaSelectedCountries,
  setSelectedPerformanceCountryCodes,
  setMandatorySelectedCountries,
  artworkIngredients,
  isShowResult,
  tab,
}: CountryListProps) => {
  const { openAlertModal } = useModal();

  const handleClickCountry = ({
    isSelected,
    countryCode,
  }: {
    isSelected?: boolean;
    countryCode: string;
  }) => {
    const newFormulaSelectedCountryCodes = isSelected
      ? formulaSelectedCountries.filter((code) => code !== countryCode)
      : [...formulaSelectedCountries, countryCode];
    const newSelectedPerformanceCountryCodes = isSelected
      ? (selectedPerformanceCountryCodes || [])?.filter((code) => code !== countryCode)
      : [...(selectedPerformanceCountryCodes || []), countryCode];
    const newMandatorySelectedCountryCodes = isSelected
      ? mandatorySelectedCountries?.filter((code) => code !== countryCode)
      : [...(mandatorySelectedCountries || []), countryCode];

    if (isPerformanceMeasurement && newSelectedPerformanceCountryCodes.length === 0) {
      message.warning('최소 1개 이상의 국가를 선택해 주세요.');
      return;
    }

    if (
      !isMandatoryScreening &&
      !isPerformanceMeasurement &&
      newFormulaSelectedCountryCodes.length === 0
    ) {
      message.warning('최소 1개 이상의 국가를 선택해 주세요.');
      return;
    }

    // 하나라도 수정된 성분 있을때 초기화 모달 띄우기
    const isChanged =
      artworkIngredients.filter(
        (item) => item.errors.length > 0 && item.errors.some(({ isUpdate }) => isUpdate),
      ).length > 0;

    if (isChanged && !isMandatoryScreening) {
      openAlertModal({
        width: 400,
        content:
          '선택한 국가를 변경하면 변경한 국가 구성에 따라\n진단 항목이 변경되고, 적용 리스트가 초기화됩니다.',
        okText: '변경',
        onOk: () => {
          setFormulaSelectedCountries?.(newFormulaSelectedCountryCodes);
          setMandatorySelectedCountries?.(newMandatorySelectedCountryCodes || []);
          if (setSelectedPerformanceCountryCodes)
            setSelectedPerformanceCountryCodes(newSelectedPerformanceCountryCodes);
        },
      });
    } else {
      setFormulaSelectedCountries?.(newFormulaSelectedCountryCodes);
      setMandatorySelectedCountries?.(newMandatorySelectedCountryCodes || []);

      if (setSelectedPerformanceCountryCodes)
        setSelectedPerformanceCountryCodes(newSelectedPerformanceCountryCodes);
    }
  };

  useEffect(() => {
    if (isPerformanceMeasurement && setSelectedPerformanceCountryCodes) {
      const filteredCountries = availableCountryCodes.filter(
        (code) => code !== 'ETC' && code !== 'CN' && code !== 'JP',
      );
      setSelectedPerformanceCountryCodes(filteredCountries);
      setFormulaSelectedCountries?.(filteredCountries);
    }
  }, [isPerformanceMeasurement]);

  return (
    <CountrySelectWrapper minHeight={availableCountryCodes.length > 4 ? 190 : 146}>
      <Flex gap={8}>
        <Typography.Text>🌎</Typography.Text>
        <Typography.Text semiBold color="SLATE_GRAY70">
          국가별 전성분 규칙에 맞게 국가를 선택하실 수 있습니다.
        </Typography.Text>
      </Flex>
      <Flex wrap="true" gap={8} gutter={{ top: 16 }}>
        {availableCountryCodes.map((countryCode) => {
          const isSelected =
            isPerformanceMeasurement && selectedPerformanceCountryCodes
              ? selectedPerformanceCountryCodes.includes(countryCode)
              : tab === 'formula'
              ? formulaSelectedCountries.includes(countryCode)
              : mandatorySelectedCountries?.includes(countryCode);
          const countryNameKo = countryCodeToNameMap[countryCode];

          return (
            <CountryItem
              key={countryCode}
              gap={8}
              isSelected={isSelected}
              clickDisabled={tab === 'requiredMsg' ? isShowResult : false}
              onClick={() => {
                if (isShowResult && tab === 'requiredMsg') return;
                handleClickCountry({
                  isSelected,
                  countryCode,
                });
              }}
            >
              <img
                src={countryImages[countryCodeToNameMap[countryCode]]}
                className="logo"
                alt=""
                style={{ width: 20 }}
              />
              <Typography.Text
                type="BODY_2"
                medium={isSelected}
                color={isSelected ? 'GRAY90' : 'GRAY50'}
              >
                {countryNameKo || '-'}
              </Typography.Text>
            </CountryItem>
          );
        })}
      </Flex>
    </CountrySelectWrapper>
  );
};

const CountrySelectWrapper = styled.div<{
  minHeight: number;
}>`
  padding: 32px;
  background-color: ${palette.PRIMARY10};
  border-radius: 8px;
  min-height: ${({ minHeight }) => `${minHeight}px`};
`;

const CountryItem = styled(Flex)<{ isSelected?: boolean; clickDisabled?: boolean }>`
  background-color: #fff;
  width: 156px;
  height: 44px;
  border-radius: 4px;
  padding: 12px 16px;

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 1px solid ${palette.PRIMARY50};
          box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
        `
      : css`
          border: 1px solid ${palette.GRAY40};
        `};

  ${({ clickDisabled }) =>
    !clickDisabled &&
    css`
      cursor: pointer;
      &:hover {
        border: 1px solid ${palette.PRIMARY50};
        span {
          color: ${palette.GRAY90};
        }
      }
    `}
`;

export default CountryList;
