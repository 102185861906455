import { Button, message } from 'antd';
import styled, { css } from 'styled-components';
import {
  ChangeEvent,
  DragEvent,
  DragEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';

import FileViewer from 'components/file/FileViewer';
import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import palette from 'lib/styles/palette';
import {
  useFormulaArtworkScreening,
  useFormulaArtworkScreeningRequest,
} from 'service/brand/artworkScreening/artworkScreening';
import useScreeningSocket from 'service/brand/artworkScreening/useScreeningSocket';
import { AiAnalyzeResult, ArtworkParamItem } from 'types/brand/product/artworkScreening';
import { useModal } from 'hook/useModal';
import IngredientAreaSelectModal from './IngredientAreaSelectModal';
import { getPDFPageCount } from 'lib/file';
import StickyButtonWrapper from '../StickyButtonWrapper';
import { PackingType } from 'types/brand/artworkScreening/artworkScreening';
import usePasteImage from './usePasteImage';
import ArtworkProgressBarModal, { delayFunc } from '../ArtworkProgressBarModal';

interface FileLoadingState {
  type: 'upload' | 'put';
  loading: boolean;
}

const ArtworkStep3 = ({
  artworkFile,
  screeningId,
  existedArtworkFile,
  setArtworkFile,
  setStep,
  setExistedArtworkFile,
}: {
  isAdmin?: boolean;
  adminToken?: string | null;
  artworkFile: Record<string, File | ArtworkParamItem | null>;
  screeningId?: number;
  existedArtworkFile?: Record<string, ArtworkParamItem | null>;
  setArtworkFile: React.Dispatch<
    React.SetStateAction<Record<string, File | ArtworkParamItem | null>>
  >;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setExistedArtworkFile: React.Dispatch<
    React.SetStateAction<Record<string, ArtworkParamItem | null>>
  >;
}) => {
  const containerRef = useRef<Record<string, HTMLDivElement | null>>({ P: null, S: null });
  const fileRef = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const [fileDragState, setFileDragState] = useState<Record<string, 'none' | 'dragging'>>();
  const [fileLoading, setFileLoading] = useState<Record<string, FileLoadingState | null>>();
  const [hoverCount, setHoverCount] = useState<Record<string, number>>({ P: 0, S: 0 });
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [socketScreeningId, setSocketScreeningId] = useState<number>();
  const [isClickedUploader, setIsClickedUploader] = useState<Record<string, boolean>>({});
  const isScreeningStatus = useScreeningSocket({ id: String(socketScreeningId) });
  const {
    openAlertModal,
    openConfirmModal,
    closeConfirmModal,
    openArtworkProgressBarModal,
    closeArtworkProgressBarModal,
  } = useModal();
  const {
    requestFormulaScreening,
    requestFormulaScreeningLoading,
    cancelMandatoryFormulaScreening,
    requestFormulaScreeningAgain,
    requestFormulaScreeningAgainLoading,
  } = useFormulaArtworkScreeningRequest();
  const { formulaScreeningArtworkFile } = useFormulaArtworkScreening({
    formulaScreeningId: screeningId,
  });

  const handleClickedUploadArea = (type: string, clicked: boolean) => {
    setIsClickedUploader(() => ({ [type]: clicked }));
  };
  const handlePasteSuccess = (type: string, file: File) => {
    setArtworkFile((prev) => ({ ...prev, [type]: file }));
  };
  usePasteImage({ handleClickedUploadArea, handlePasteSuccess });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScreeningSuccess = () => {
    if (!screeningId) return;

    const requestScreeningAgain = (aiAnalyzeResult: AiAnalyzeResult, selectedImageId?: number) => {
      requestFormulaScreeningAgain(
        {
          formulaScreeningArtworkImageId:
            selectedImageId ?? aiAnalyzeResult.splitImages[0].formulaScreeningArtworkImageId,
          formulaScreeningId: screeningId,
        },
        {
          onSuccess: () => {
            delayFunc(2000, () => {
              setLoadingVisible(false);
              openArtworkProgressBarModal({
                type: 'COMPLETE',
                visible: true,
                screeningStatus: String(isScreeningStatus),
                onOk: () => {
                  closeArtworkProgressBarModal();
                  setStep(4);
                },
              });
            });
          },
          onError: () => {
            setLoadingVisible(false);
          },
        },
      );
    };

    const selectIngredientArea = (aiAnalyzeResult: AiAnalyzeResult) => {
      delayFunc(2000, () => {
        setLoadingVisible(false);
        openArtworkProgressBarModal({
          type: 'COMPLETE',
          visible: true,
          screeningStatus: String(isScreeningStatus),
          onOk: () => {
            closeArtworkProgressBarModal();
            openConfirmModal({
              footer: null,
              width: 834,
              bodyStyle: { padding: '24px 24px 32px 40px' },
              title: (
                <Flex gap={8} align="center">
                  <Typography.Text>전성분 영역 선택</Typography.Text>
                  <Tip bodyStyle={{ top: '-9px' }} trigger="click">
                    <Typography.Text type="SMALL" color="GRAY90">
                      전성분 영역을 선택하면 결과 화면으로 이동합니다.
                    </Typography.Text>
                  </Tip>
                </Flex>
              ),
              content: (
                <IngredientAreaSelectModal
                  splitImages={aiAnalyzeResult.splitImages}
                  okLoading={requestFormulaScreeningAgainLoading}
                  onOk={(formulaScreeningArtworkImageId: number) => {
                    setLoadingVisible(true);
                    requestScreeningAgain(aiAnalyzeResult, formulaScreeningArtworkImageId);
                    closeConfirmModal();
                  }}
                />
              ),
            });
          },
        });
      });
    };

    formulaScreeningArtworkFile(
      { formulaScreeningId: screeningId },
      {
        onSuccess: (res) => {
          const { aiAnalyzeResult, artworks } = res.data.result;
          artworks.forEach((item) => {
            setExistedArtworkFile((prev) => {
              return { ...prev, [item.packingType]: item };
            });
          });

          if (aiAnalyzeResult.splitImages.length === 0) {
            handleScreeningNotFound();
          } else if (aiAnalyzeResult.splitImages.length === 1) {
            requestScreeningAgain(aiAnalyzeResult);
          } else {
            selectIngredientArea(aiAnalyzeResult);
          }
        },
      },
    );
  };

  const handleScreeningNotFound = () => {
    setLoadingVisible(false);
    openAlertModal({
      content: `INGREDIENTS가 포함되어 있지 않습니다.\n포함된 이미지로 업로드해 주세요.`,
      onOk: () => {
        setLoadingVisible(false);
      },
      okText: '확인',
      noCloseIcon: true,
      noCancelButton: true,
    });
  };
  const handleScreeningError = () => {
    setLoadingVisible(false);
    return message.error('스크리닝 중 오류가 발생했습니다.');
  };
  const handleScreeningMaxFileSize = () => {
    setLoadingVisible(false);
    openAlertModal({
      width: 400,
      content: (
        <Typography.Text type="BODY_2" color="GRAY90">
          아트웍 파일의 전체 면적이 큰 경우
          <br />
          분석에 시간이 많이 소요될 수 있습니다.
          <br />
          AI 분석 최적화를 위해
          <br />
          이미지 영역을 잘라서 업로드해주세요.
        </Typography.Text>
      ),
      okText: '확인',
      onOk: () => {
        setLoadingVisible(false);
      },
    });
  };

  useEffect(() => {
    if (isScreeningStatus === undefined) return;

    setSocketScreeningId(undefined);

    if (isScreeningStatus === 'COMPLETE' && screeningId) {
      handleScreeningSuccess();
    } else if (isScreeningStatus === 'NOT_FOUND_INGREDIENT') {
      handleScreeningNotFound();
    } else if (isScreeningStatus === 'SERVER_ERROR') {
      handleScreeningError();
    } else if (isScreeningStatus === 'MAX_FILE_SIZE') {
      handleScreeningMaxFileSize();
    }
  }, [isScreeningStatus]);

  const artworkUploadContainer: {
    type: PackingType;
    exampleImages: { id: number; src: string; width: number }[];
  }[] = [
    {
      type: PackingType.PRIMARY,
      exampleImages: [
        {
          id: 1,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening1-1.jpeg`,
          width: 280,
        },
        {
          id: 2,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening1-2.jpeg`,
          width: 280,
        },
        {
          id: 3,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening1-3.jpeg`,
          width: 280,
        },
      ],
    },
    {
      type: PackingType.SECONDARY,
      exampleImages: [
        {
          id: 1,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening2-1.jpeg`,
          width: 280,
        },
        {
          id: 2,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening2-2.jpeg`,
          width: 280,
        },
        {
          id: 3,
          src: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Artwork_Screening2-3.jpeg`,
          width: 130,
        },
      ],
    },
  ];

  const commonFileUpload = async (filesList: FileList | File[], type: string) => {
    const files: File[] = [];

    if (filesList.length > 1) {
      return message.warning('1개의 파일만 업로드 가능합니다.');
    }

    const handleLoadingState = (state: boolean) => {
      setFileLoading((prev) => ({
        ...prev,
        [type]: {
          type: 'upload',
          loading: state,
        },
      }));
    };

    for (const file of filesList) {
      const filename = file.name.toLowerCase();
      if (
        !filename.endsWith('.png') &&
        !filename.endsWith('.jpeg') &&
        !filename.endsWith('.jpg') &&
        !filename.endsWith('.pdf')
      ) {
        return message.warning('pdf, 이미지 파일만 업로드 가능합니다.');
      }
      files.push(file);
    }

    handleLoadingState(true);

    if ((files[0]?.name).toLowerCase().endsWith('.pdf')) {
      const pageCount = await getPDFPageCount(files[0]);
      if (pageCount > 1) {
        message.warning('한 페이지의 Artwork 파일만 업로드 가능합니다.');
        handleLoadingState(false);

        return;
      } else {
        if (!pageCount) {
          handleLoadingState(false);

          return;
        }

        setArtworkFile((prev) => ({ ...prev, [type]: files[0] }));
        message.success(`정상적으로 업로드되었습니다.`);
        handleLoadingState(false);

        return;
      }
    } else {
      setArtworkFile((prev) => ({ ...prev, [type]: files[0] }));

      message.success(`정상적으로 업로드되었습니다.`);
      handleLoadingState(false);
    }
  };

  const handleFileDragLeave = (_: DragEvent<HTMLDivElement>, type: string) => {
    setHoverCount((prev) => {
      return {
        ...prev,
        [type]: prev[type] - 1,
      };
    });

    if (hoverCount[type] === 1) {
      setFileDragState((prev) => {
        return {
          ...prev,
          [type]: 'none',
        };
      });
    }
  };

  const handleFileDragUpload = (e: DragEvent<HTMLDivElement>, type: string) => {
    e.preventDefault();
    handleFileDragLeave(e, type);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    commonFileUpload(e.dataTransfer.files, type);
  };

  const handleFileDragEnter = (_: DragEvent<HTMLDivElement>, type: string) => {
    setHoverCount((prev) => {
      return {
        ...prev,
        [type]: prev[type] + 1,
      };
    });
    setFileDragState((prev) => {
      return {
        ...prev,
        [type]: 'dragging',
      };
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    if (!e.target.files?.length) return;

    commonFileUpload(e.target.files, type);

    e.target.value = '';
  };

  const preventDefault: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const referToFile = (type: string) => fileRef.current[type]?.click();

  const handleRequest = () => {
    if (!screeningId) return;

    setLoadingVisible(true);

    const uploadArtworkFiles = Object.entries(artworkFile).filter(([_, file]) => !!file);

    const isPrimaryFileDeleted =
      existedArtworkFile?.[PackingType.PRIMARY] &&
      !(artworkFile[PackingType.PRIMARY] as ArtworkParamItem)?.formulaScreeningArtworkId;

    const filteredUploadArtworkParams: {
      artwork?: File | null;
      packingType: PackingType;
    }[] = uploadArtworkFiles
      .filter(([_, file]) => !(file as ArtworkParamItem).formulaScreeningArtworkId && !!file)
      .filter(([type, file]) => existedArtworkFile?.[type]?.filename !== (file as File).name)
      .map(([type, file]) => ({
        artwork: file as File,
        packingType: type === PackingType.PRIMARY ? PackingType.PRIMARY : PackingType.SECONDARY,
      }));

    const hasPrimaryUploadFile = filteredUploadArtworkParams.find(
      ({ artwork, packingType }) => packingType === PackingType.PRIMARY && !!artwork,
    );

    const isFilesUnchanged = () => {
      if (
        existedArtworkFile &&
        Object.keys(existedArtworkFile).length === 0 &&
        Object.values(artworkFile).length > 0
      ) {
        return false;
      }
      for (const key in existedArtworkFile) {
        if (existedArtworkFile[key]?.filename !== (artworkFile[key] as File)?.name) {
          return false;
        }
      }
      return true;
    };

    let requestParam = filteredUploadArtworkParams;

    if (isFilesUnchanged()) {
      requestParam = [];
    } else if (isPrimaryFileDeleted) {
      if (!!hasPrimaryUploadFile) {
        requestParam = filteredUploadArtworkParams;
      } else {
        requestParam = filteredUploadArtworkParams.concat([{ packingType: PackingType.PRIMARY }]);
      }
    }

    requestFormulaScreening(
      {
        formulaScreeningId: screeningId,
        ...(requestParam.length > 0 && {
          params: requestParam,
        }),
      },
      {
        onSuccess: () => {
          setSocketScreeningId(screeningId);
        },
        onError: () => {
          setLoadingVisible(false);
        },
      },
    );
  };

  const handleCancel = () => {
    if (!screeningId) return;
    cancelMandatoryFormulaScreening(
      { formulaScreeningId: screeningId },
      {
        onSuccess: () => {
          setLoadingVisible(false);
        },
        onError: () => {
          setLoadingVisible(false);
        },
      },
    );
  };

  const isContainSecondaryFile = useMemo(() => {
    return Object.entries(artworkFile)
      .map(([type, file]) => {
        if (type === PackingType.PRIMARY) return null;
        return {
          ...(!!file && { type }),
        };
      })
      .find((item) => item?.type === PackingType.SECONDARY);
  }, [artworkFile]);

  return (
    <Container>
      <InnerContainer>
        <ArtworkProgressBarModal
          type="IN_PROGRESS"
          visible={loadingVisible}
          screeningStatus={String(isScreeningStatus)}
          canCancel={!requestFormulaScreeningLoading}
          onCancel={handleCancel}
        />
        <Flex
          gap={8}
          style={{
            marginTop: 56,
            paddingBottom: 8,
            borderBottom: `2px solid ${palette.PRIMARY50}`,
            width: '100%',
          }}
          align="center"
        >
          <Flex gap={4} align="center">
            <Typography.Text type="TITLE_1">Artwork 업로드</Typography.Text>
          </Flex>
        </Flex>
        <DescriptionContainer gap={4} dir="column" style={{ height: 116 }}>
          <Typography.Text style={{ fontSize: 14 }}>
            · 고화질 이미지로 업로드해 주세요. 화질이 떨어지면 스크리닝에 오류가 있을 수 있습니다.
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            · 캡쳐한 이미지를 복사한 후 영역을 클릭해서 붙여 넣으면 업로드됩니다.
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
            ·
            <span style={{ textDecoration: 'underline', marginLeft: 4 }}>
              아래 업로드 예시 이미지를 참고하여 단상자 포장, 용기 포장을 구분해서 업로드해 주세요.
            </span>
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            · 한가지 제품만 스크리닝이 가능하며 Artwork 파일에 여러개의 전성분이 있는 경우 영역을
            선택하여 검토할 수 있습니다.
          </Typography.Text>
        </DescriptionContainer>
        {artworkUploadContainer.reverse().map(({ type, exampleImages }) => {
          return (
            <div
              key={type}
              style={{
                borderBottom:
                  type === PackingType.SECONDARY ? `1px solid ${palette.GRAY30}` : 'none',
                paddingBottom: type === PackingType.SECONDARY ? '56px' : '0px',
                paddingTop: type === PackingType.PRIMARY ? '32px' : '0px',
              }}
            >
              <Flex dir="column" gap={16} gutter={{ top: 24 }}>
                <Typography.Text asterisk={type === PackingType.SECONDARY} type="TITLE_2">
                  {type === PackingType.PRIMARY ? '용기' : '단상자'} 포장 업로드 예시
                </Typography.Text>
                <Flex gap={24} justify="center" style={{ width: '100%' }}>
                  {exampleImages.map(({ id, src, width }) => {
                    return (
                      <ImgItem justify="center" align="center" key={id}>
                        <img style={{ maxHeight: 160 }} src={src} alt="써티코스" />
                      </ImgItem>
                    );
                  })}
                </Flex>
              </Flex>
              <ArtworkContainer>
                {!artworkFile[type] ? (
                  <UploadContainer
                    className={`uploader_${type}`}
                    clicked={isClickedUploader?.[type]}
                    ref={(el) => (containerRef.current[type] = el)}
                    dragging={fileDragState?.[type] === 'dragging'}
                    onDragEnter={(e) => handleFileDragEnter(e, type)}
                    onDragLeave={(e) => handleFileDragLeave(e, type)}
                    onDragOver={preventDefault}
                    onDrop={(e) => handleFileDragUpload(e, type)}
                    align="center"
                    justify="center"
                  >
                    <input
                      type="file"
                      ref={(el) => (fileRef.current[type] = el)}
                      multiple={true}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileUpload(e, type)}
                      accept=".pdf, .jpg, .jpeg, .png,.PDF,.JPG,.JPEG,.PNG"
                    />
                    <InputCursor />
                    {fileLoading?.[type]?.type === 'upload' && fileLoading?.[type]?.loading && (
                      <Flex dir="column" align="center" gap={8}>
                        <LoadingIndicator size="md" />
                        <Typography.Text
                          style={{
                            fontSize: 16,
                            color: palette.SLATE_GRAY70,
                            fontWeight: 500,
                          }}
                        >
                          파일을 업로드 하는 중...
                        </Typography.Text>
                      </Flex>
                    )}
                    {!fileLoading?.[type]?.loading && (
                      <Flex
                        dir="column"
                        align="center"
                        justify="center"
                        onClick={() => referToFile(type)}
                      >
                        <Icon
                          name="uploadDocIcon"
                          size={48}
                          color="SLATE_GRAY50"
                          style={{ marginBottom: 16 }}
                        />
                        {type === PackingType.SECONDARY && (
                          <Typography.Text
                            style={{
                              color: palette.PRIMARY50,
                              fontSize: 16,
                              fontWeight: 600,
                              // marginBottom: 8,
                            }}
                          >
                            INGREDIENT 포함 필수
                          </Typography.Text>
                        )}
                        <Typography.Text
                          style={{
                            color: palette.SLATE_GRAY60,
                            fontSize: 16,
                            fontWeight: 500,
                            marginBottom: 8,
                          }}
                        >
                          버튼 클릭 혹은 파일 끌어서 넣기
                        </Typography.Text>
                        <Typography.Text
                          style={{
                            color: palette.SLATE_GRAY60,
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          업로드 가능한 파일 : pdf, jpg, jpeg, png
                        </Typography.Text>
                        <Button
                          loading={fileLoading?.[type]?.loading}
                          icon={<Icon name="upload" size={18} color="GRAY50" />}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderColor: palette.GRAY40,
                            color: palette.GRAY70,
                            height: 32,
                            marginTop: 16,
                            padding: '6px 8px',
                          }}
                        >
                          파일 업로드
                        </Button>
                      </Flex>
                    )}
                  </UploadContainer>
                ) : (
                  <PreviewContainer dir="column" gap={16} style={{ width: '100%' }}>
                    <FileViewer
                      title=""
                      file={
                        (artworkFile[type] as File) || (artworkFile[type] as ArtworkParamItem)?.url
                      }
                      bodyStyle={{ height: 320, borderColor: palette.GRAY30, overflowY: 'hidden' }}
                    />
                    <FileItem align="center" justify="space-between">
                      <Flex align="center" gap={8}>
                        <Icon name="clip" size={18} color="SLATE_GRAY70" />
                        <Typography.Text style={{ fontSize: 12, color: palette.SLATE_GRAY70 }}>
                          {(artworkFile[type] as File)?.name ||
                            (artworkFile[type] as ArtworkParamItem)?.filename}
                        </Typography.Text>
                      </Flex>
                      <Icon
                        name="close"
                        size={18}
                        color="SLATE_GRAY70"
                        onClick={() => {
                          setArtworkFile((prev) => ({ ...prev, [type]: null }));
                        }}
                      />
                    </FileItem>
                  </PreviewContainer>
                )}
              </ArtworkContainer>
            </div>
          );
        })}
      </InnerContainer>
      <StickyButtonWrapper>
        <Flex align="center" justify={'end'} style={{ width: '100%' }}>
          <Flex align="center" gap={16}>
            <Button
              htmlType="button"
              style={{
                width: 200,
                height: 56,
                fontSize: 18,
              }}
              type="default"
              onClick={() => setStep(2)}
            >
              이전
            </Button>
            <Button
              style={{
                width: 200,
                height: 56,
                fontSize: 18,
              }}
              htmlType="button"
              type="primary"
              onClick={handleRequest}
              disabled={!isContainSecondaryFile}
            >
              스크리닝 하기
            </Button>
          </Flex>
        </Flex>
      </StickyButtonWrapper>
    </Container>
  );
};

const Container = styled.div``;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

const ArtworkContainer = styled.div``;
const PreviewContainer = styled(Flex)`
  margin-top: 24px;
  img {
    width: auto;
    max-width: 100%;
    max-height: 288px;
  }
  .react-pdf__Page__canvas {
    max-height: 288px;
    max-width: 100%;
    object-fit: contain;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const ImgItem = styled(Flex)`
  background-color: ${palette.GRAY10};
  border-radius: 4px;
  width: 304px;
  height: 180px;
  border: 1px solid ${palette.GRAY30};
`;

const UploadContainer = styled(Flex)<{ dragging: boolean; clicked: boolean }>`
  position: relative;
  width: 100%;
  background-color: white;
  height: 320px;
  border-radius: 8px;
  padding: 52px 32px;
  margin-top: 24px;
  cursor: pointer;
  transition: border 100ms linear;
  border: 1px solid ${palette.GRAY30};
  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
  `}
  ${({ clicked }) =>
    clicked &&
    css`
      background-color: ${palette.ETC_BG_BLUE};
      ${InputCursor} {
        display: block;
      }
    `}
`;

const FileItem = styled(Flex)`
  width: 100%;
  border-radius: 4px;
  background-color: ${palette.SLATE_GRAY10};
  padding: 11px 16px;
  color: ${palette.SLATE_GRAY70};
`;

const InputCursor = styled.div`
  position: absolute;
  left: 24px;
  top: 28px;
  width: 1px;
  height: 20px;
  background-color: ${palette.GRAY90};
  display: none;
  @keyframes blink {
    0%,
    100% {
      opacity: 1;
      visibility: visible;
    }
    50% {
      opacity: 0;
      visibility: hidden;
    }
  }
  animation: blink 1s infinite;
`;

export default ArtworkStep3;
