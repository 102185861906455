import { useState } from 'react';
import styled from 'styled-components';

import Typography from 'components/system/general/Typography';
import NoticeCard from 'components/system/data/NoticeCard';
import ManuRegisterCreateForm from 'components/auth/manu/ManuRegisterCreateForm';

const ManuRegisterFormPageBlock = styled.div`
  max-width: 920px;
  margin: 0 auto;
`;

const ManuRegisterFormPage = () => {
  const [tab, setTab] = useState<'company' | 'user'>('company');
  const handleChangeTab = (tab: 'company' | 'user') => {
    setTab(tab);
  };
  return (
    <ManuRegisterFormPageBlock>
      <Typography.Text light type="HEADLINE_1">
        정보 입력
      </Typography.Text>
      <Typography.Text type="BODY_2" gutter={{ top: 4, bottom: 18 }} color="GRAY70">
        정보를 정확하게 입력해 주세요.
      </Typography.Text>
      <NoticeCard title="안내사항" style={{ marginTop: 40 }}>
        <ul>
          {tab === 'company' ? (
            <>
              <li>한 사업자당 한 계정만 가입이 가능합니다.</li>
              <li>
                보유하신 공장 소재지는 최대 5개 까지 입력 가능하며 기존 보유하신 cGMP(ISO22716) 과
                동일한 영문으로 작성해주세요.
              </li>
              <li key="5">
                입력하신 정보가 잘못된 경우 관리자가 확인하여 수정할 수 있으니 참고바랍니다.
              </li>
            </>
          ) : (
            <>
              <li>
                슈퍼유저 정보에 기재하신 이메일로 가입 승인 및 알림이 전송되며, 자동 발행 양식에
                해당 이메일이 사용되므로 책임자의 이메일을 입력해 주세요.
              </li>
              <li>
                슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능하며, 모든 영역에서 최고 권한을 가진
                계정입니다.
              </li>
              <li>슈퍼유저는 가입 후 하위 담당자를 관리하실 수 있습니다.</li>
            </>
          )}
        </ul>
      </NoticeCard>
      <ManuRegisterCreateForm currentTab={tab} handleChangeTab={handleChangeTab} />
    </ManuRegisterFormPageBlock>
  );
};

export default ManuRegisterFormPage;
