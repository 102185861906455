import { Fragment, useRef, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputRef,
  message,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';

import Typography from 'components/system/general/Typography';
import { FileUpload, Tip } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';
import { exceptKoreanRule, generateFormRules, phoneRule, requireRule } from 'lib/validate';
import palette from 'lib/styles/palette';
import { ManuForm } from './hooks/useManuRegisterForm';
import ShortenText from 'components/ui/ShortenText';
import { downloadFile } from 'lib/file';
import * as authApi from 'lib/api/auth';
import { CompanyType } from 'types/company';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import { AllManufacturerCompany } from 'types/manufacturer/company';

export interface FormInitialData {
  user: any;
  company: any;
  factories: {
    uploadedFile?: File | { url: string; name?: string } | null;
    isCGMP?: boolean;
    seq: number;
    companyId?: number;
    companyFactoryId?: number;
    addressKo?: string;
    tel?: string;
    fax?: number;
    cgmpUploadFile?: File;
    cgmpUploadFileUrl?: string;
    iso22716ExpireDate?: string;
    iso22716UploadFileUrl?: string;
    documentCode?: string | null;
    zipCode?: string;
  }[];
}

interface ManuCompanyFormProps {
  isVisible?: boolean;
  isEdit?: boolean;
  form: FormInstance<ManuForm>;
  factorycGMP: {
    [key: number]:
      | File
      | {
          filename: string;
          url: string;
        }
      | null;
  };
  searchValue: string;
  allManufacturers?: AllManufacturerCompany[];
  handleSelectSearch: (search: string) => void;
  handleUploadCgmpFile?: (file: File, factoryKey: number) => void;
  handleChangeDocumentCode?: (documentCode: string, factoryKey: number) => void;
  handleChangeExpiryDate?: (date: string, factoryKey: number) => void;
}

const BizLicenseFormItem = ({
  isEdit,
  licenseFileKo,
  licenseFileEn,
  companyNameKo,
}: {
  isEdit?: boolean;
  licenseFileKo?: { name: string; url: string };
  licenseFileEn?: { name: string; url: string };
  companyNameKo: string;
}) => {
  return (
    <>
      <Flex gap={16} dir="row">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label={'국문 사업자등록증'}
            required
            name="bizLicenseFile"
            rules={[requireRule]}
          >
            {isEdit ? (
              <Button
                icon={<Icon size={18} name="download" color="PRIMARY50" />}
                type="default"
                style={{ background: 'white', border: `1px solid ${palette.PRIMARY50}` }}
                onClick={() =>
                  downloadFile(licenseFileKo?.url, `${companyNameKo}_국문 사업자등록증`)
                }
              >
                파일 다운로드
              </Button>
            ) : (
              <FileUpload
                accept=".pdf,.jpg,.jpeg,.png"
                isView={false}
                readOnly={!!isEdit}
                bodyStyle={{ width: 336, height: 448 }}
                filenameDisplayOptions={{
                  isShortenFilename: true,
                  shortenMaxWidth: 200,
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label={
              <Row gutter={8} align="middle">
                <Col style={{ fontSize: '18px' }}>영문 사업자등록증</Col>
              </Row>
            }
            name="bizLicenseEnFile"
          >
            {isEdit ? (
              <Flex gap={8} align="center">
                {licenseFileEn?.name ? (
                  <Button
                    icon={<Icon size={18} name="download" color="PRIMARY50" />}
                    type="default"
                    style={{ background: 'white', border: `1px solid ${palette.PRIMARY50}` }}
                    onClick={() =>
                      downloadFile(licenseFileEn?.url, `${companyNameKo}_영문 사업자등록증`)
                    }
                  >
                    파일 다운로드
                  </Button>
                ) : (
                  <Button
                    icon={<Icon size={18} name="upload" color="GRAY50" />}
                    disabled
                    type="default"
                    style={{ background: 'white', border: `1px solid ${palette.GRAY40}` }}
                  >
                    파일 선택
                  </Button>
                )}
              </Flex>
            ) : (
              <FileUpload
                accept=".pdf,.jpg,.jpeg,.png"
                isView={false}
                readOnly={!!isEdit}
                bodyStyle={{ width: 336, height: 448 }}
                filenameDisplayOptions={{
                  isShortenFilename: true,
                  shortenMaxWidth: 200,
                }}
              />
            )}
          </Form.Item>
        </Col>
      </Flex>
    </>
  );
};

const ManuCompanyForm = ({
  isVisible = true,
  isEdit,
  form,
  factorycGMP,
  searchValue,
  allManufacturers,
  handleSelectSearch,
  handleUploadCgmpFile,
  handleChangeDocumentCode,
  handleChangeExpiryDate,
}: ManuCompanyFormProps) => {
  const { user } = useSelector(({ auth }) => ({ user: auth.user }), shallowEqual);
  const inputRef = useRef<InputRef | null>(null);
  const [isBizNumberCheckSuccess, setIsBizNumberCheckSuccess] = useState(false);

  const normalizeBiznumber = (value: string) => {
    if (value.length > 12) return value.substring(0, 12);
    if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
    if (value.slice(-1) === '-') return value;
    return value
      .replace(/[-]/g, '')
      .replace(/^(\d{3})(\d{1,})/, '$1-$2')
      .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
  };

  return (
    <Container style={{ display: isVisible ? 'block' : 'none' }}>
      <Form.Item
        shouldUpdate={(prev, next) => prev.companyNameKo !== next.companyNameKo}
        label={
          <Row gutter={8} align="middle">
            <Col>
              <Typography.Text type="TITLE_2">회사명 (국문/영문)</Typography.Text>
            </Col>
            {!isEdit && (
              <Col>
                <Tip trigger="click">
                  <Typography.Text type="SMALL">
                    사업자등록증의 사명과 동일하게 입력하되, 띄어쓰기 없이 입력해 주세요.
                    <br />
                    중복 데이터 또는 오인 표기를 줄이기 위함이므로,
                  </Typography.Text>
                  <Typography.Text type="SMALL" color="PRIMARY50">
                    회사명에 띄어쓰기가 입력되지 않아도 무방합니다.
                  </Typography.Text>
                </Tip>
              </Col>
            )}
          </Row>
        }
        style={{ marginBottom: 0 }}
        required
      >
        {({ getFieldValue, setFieldsValue }) => (
          <>
            <Flex gap={16}>
              <Form.Item
                name="companyNameKo"
                rules={[requireRule]}
                style={{ marginBottom: 0, flex: 1 }}
              >
                <Select
                  disabled={isEdit}
                  showSearch
                  searchValue={searchValue}
                  onSearch={handleSelectSearch}
                  placeholder="국문 회사명 검색 또는 선택"
                  notFoundContent={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ? (
                      <Typography.Text
                        style={{ cursor: 'pointer' }}
                        color="GRAY90"
                        type="BODY_2"
                        onClick={() =>
                          setFieldsValue({
                            companyNameKo: 'companyNameKoDirect',
                          })
                        }
                      >
                        직접입력
                      </Typography.Text>
                    ) : null
                  }
                  onSelect={(value) => {
                    const selectedCorp = allManufacturers?.find(
                      ({ manufacturerProfiles }) =>
                        manufacturerProfiles.find(({ languageCode }) => languageCode === 'KO')
                          ?.companyName === value,
                    );
                    const isAlreadyReleated = selectedCorp?.isCompanyRelated;

                    const bizNumber = selectedCorp?.bizNumber;

                    if (value === 'companyNameKoDirect') {
                      setTimeout(() => inputRef?.current?.focus());
                      setFieldsValue({
                        bizNumber: '',
                        companyNameEn: '',
                      });
                    } else if (isAlreadyReleated) {
                      message.error('이미 가입되어 있는 회사입니다. ‘이용 문의’로 문의해 주세요');
                      setFieldsValue({
                        companyNameKo: null,
                        companyNameEn: null,
                        bizNumber: null,
                      });
                    } else {
                      setFieldsValue({
                        bizNumber: normalizeBiznumber(String(bizNumber)),
                        companyNameEn: selectedCorp?.manufacturerProfiles.find(
                          ({ languageCode }) => languageCode === 'EN',
                        )?.companyName,
                      });
                    }
                  }}
                >
                  <Select.Option key="companyNameKoDirect" value="companyNameKoDirect">
                    직접입력
                  </Select.Option>
                  {allManufacturers?.map((manufacturer) => {
                    const companyNameKo = manufacturer.manufacturerProfiles.find(
                      (item) => item.languageCode === 'KO',
                    )?.companyName;
                    return (
                      <Select.Option key={manufacturer.manufacturerId} value={companyNameKo}>
                        {companyNameKo}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={
                  getFieldValue('companyNameKo') !== 'companyNameKoDirect'
                    ? 'companyNameKo'
                    : 'companyNameKoDirect'
                }
                rules={[requireRule]}
                style={{ marginBottom: 16, flex: 1 }}
              >
                <Input
                  ref={inputRef}
                  disabled={
                    isEdit ||
                    (!isEdit &&
                      !!getFieldValue('companyNameKo') &&
                      getFieldValue('companyNameKo') !== 'companyNameKoDirect')
                  }
                  placeholder={
                    getFieldValue('companyNameKo') === 'companyNameKoDirect'
                      ? '국문 회사명 입력'
                      : undefined
                  }
                  style={
                    isEdit
                      ? undefined
                      : { color: palette.GRAY90, backgroundColor: palette.ETC_WHITE }
                  }
                />
              </Form.Item>
            </Flex>
            <Form.Item name="companyNameEn" rules={[requireRule, exceptKoreanRule]}>
              <Input disabled={isEdit} placeholder="영문 회사명 입력" />
            </Form.Item>
            {!isEdit && (
              <Form.Item
                label="사업자등록번호"
                validateStatus={isBizNumberCheckSuccess ? 'success' : undefined}
                help={isBizNumberCheckSuccess ? '사용 가능한 사업자등록번호' : undefined}
                name="bizNumber"
                normalize={(value) => normalizeBiznumber(value)}
                rules={
                  user?.company?.isTestCompany
                    ? []
                    : [
                        requireRule,
                        {
                          pattern: /^\d{3}-\d{2}-\d{5}$/,
                          message: '올바르지 않은 사업자등록번호 형식',
                        },
                        {
                          validator: async (_, bizNumber) => {
                            if (isEdit || !/^\d{3}-\d{2}-\d{5}$/.test(bizNumber)) return;

                            try {
                              await authApi.bizNumberDupCheck({
                                bizNumber,
                                companyType: CompanyType.MANUFACTURE,
                              });
                              setIsBizNumberCheckSuccess(true);
                            } catch (e) {
                              setIsBizNumberCheckSuccess(false);
                              throw new Error('이미 가입되어 있는 사업자등록번호');
                            }
                            return;
                          },
                        },
                      ]
                }
              >
                <Input
                  disabled={
                    isEdit ||
                    (!!getFieldValue('companyNameKo') &&
                      getFieldValue('companyNameKo') !== 'companyNameKoDirect')
                  }
                  autoComplete="off"
                  onChange={() => setIsBizNumberCheckSuccess(false)}
                />
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>

      {isEdit && (
        <>
          <Flex gap={16}>
            <Form.Item
              label="대표자 성명 (국문/영문)"
              name="ceoNameKo"
              style={{ flex: 1 }}
              required
            >
              <Input disabled />
            </Form.Item>
            <Form.Item label="" name="ceoNameEn" style={{ flex: 1, marginTop: 36 }} required>
              <Input disabled />
            </Form.Item>
          </Flex>
          <Form.Item label="회사 주소 (국문/영문)" name="addressKo" style={{ flex: 1 }} required>
            <Input disabled />
          </Form.Item>
          <Form.Item label="" name="addressEn" style={{ flex: 1 }} required>
            <Input disabled />
          </Form.Item>
          <BizLicenseFormItem
            companyNameKo={form.getFieldValue('companyNameKo')}
            isEdit={isEdit}
            licenseFileKo={form.getFieldValue('bizLicenseFile')}
            licenseFileEn={form.getFieldValue('bizLicenseEnFile')}
          />
          <Flex gap={16}>
            <Form.Item label="사업자등록번호" name="bizNumber" required style={{ flex: 1 }}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              rules={generateFormRules({
                required: true,
                minLength: 5,
                maxLength: 10,
                onlyNumber: true,
              })}
              label="회사 우편번호"
              name="zipCode"
              style={{ flex: 1 }}
              required
            >
              <Input disabled />
            </Form.Item>
          </Flex>
        </>
      )}
      <Flex gap={16}>
        <Form.Item label="회사 전화번호" name="tel" rules={[requireRule]} style={{ flex: 1 }}>
          <Input placeholder="전화번호 입력" disabled={isEdit} />
        </Form.Item>
        {form.getFieldValue('fax') && (
          <Form.Item label="회사 팩스번호" name="fax" rules={[phoneRule]} style={{ flex: 1 }}>
            <Input placeholder="숫자로 입력" disabled={isEdit} />
          </Form.Item>
        )}
      </Flex>
      {isEdit && (
        <>
          {form.getFieldValue('homepageUrl') && (
            <Form.Item label="홈페이지 URL" name="homepageUrl" style={{ flex: 1 }}>
              <Input disabled />
            </Form.Item>
          )}
          {form.getFieldValue('crcsbUrl') && (
            <Form.Item label="화장품제조업 등록필증" name="crcsbUrl">
              <Button
                icon={<Icon size={18} name="download" color="PRIMARY50" />}
                type="default"
                style={{ background: 'white', border: `1px solid ${palette.PRIMARY50}` }}
                onClick={() =>
                  downloadFile(
                    form.getFieldValue('crcsbUrl'),
                    `${form.getFieldValue('companyNameKo')}_화장품제조업 등록필증`,
                  )
                }
              >
                파일 다운로드
              </Button>
            </Form.Item>
          )}
        </>
      )}
      {!isEdit && (
        <BizLicenseFormItem
          companyNameKo={form.getFieldValue('companyNameKo')}
          licenseFileKo={form.getFieldValue('bizLicenseFile')}
          licenseFileEn={form.getFieldValue('bizLicenseEnFile')}
          isEdit={isEdit}
        />
      )}
      {!isEdit && (
        <Form.Item label="화장품제조업 등록필증" name="crcsbUrl">
          <FileUpload
            accept=".pdf,.jpg,.jpeg,.png"
            isView={false}
            readOnly={!!isEdit}
            bodyStyle={{ width: 336, height: 448 }}
            filenameDisplayOptions={{
              isShortenFilename: true,
              shortenMaxWidth: 200,
            }}
          />
        </Form.Item>
      )}
      <Form.List name="factories" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, i) => {
              return (
                <Form.Item
                  key={field.key}
                  shouldUpdate={(prev, next) => prev.factories !== next.factories}
                >
                  {({ getFieldValue }) => {
                    const factoryCgmp = factorycGMP[field.name];
                    const isCgmp = !!getFieldValue(['factories', field.name, 'cgmpUploadFileUrl']);
                    const cgmpUploadFileUrl =
                      getFieldValue(['factories', field.name, 'cgmpUploadFileUrl']) ||
                      getFieldValue(['factories', field.name, 'iso22716UploadFileUrl']);

                    return (
                      <Fragment key={field.key}>
                        <Flex align="middle" dir="column">
                          <Row wrap={false} align="middle" gutter={8}>
                            <Col flex="1">
                              <Form.Item
                                required
                                label={
                                  <Flex
                                    justify="space-between"
                                    align="center"
                                    style={{ width: '100%' }}
                                  >
                                    제 {field.name + 1}공장 소재지 (국문)
                                    {!isEdit && field.name !== 0 && (
                                      <Icon
                                        name="close"
                                        size={20}
                                        color="GRAY60"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      />
                                    )}
                                  </Flex>
                                }
                                name={[field.name, 'addressKo']}
                                rules={[requireRule]}
                              >
                                <Input disabled={isEdit} placeholder="국문으로 입력" />
                              </Form.Item>
                              <Flex gap={16}>
                                <Form.Item
                                  required
                                  label={`제 ${field.name + 1}공장 cGMP(ISO22716)`}
                                  name={[field.name, 'cgmpUploadFile']}
                                  style={{ flex: 1 }}
                                  rules={generateFormRules({ required: true })}
                                >
                                  <Flex gap={16} align="center">
                                    {!isEdit ? (
                                      <Upload
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        itemRender={() => null}
                                        beforeUpload={(_, fileList) => {
                                          handleUploadCgmpFile?.(fileList[0], field.name);
                                          handleChangeDocumentCode?.('CGMP', field.name);

                                          return false;
                                        }}
                                      >
                                        <Button
                                          style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                          icon={<Icon size={18} name="upload" color="PRIMARY50" />}
                                        >
                                          파일 선택
                                        </Button>
                                      </Upload>
                                    ) : (
                                      <Flex gap={16} align="center">
                                        <Button
                                          icon={
                                            <Icon size={18} name="download" color="PRIMARY50" />
                                          }
                                          disabled={!cgmpUploadFileUrl}
                                          type="default"
                                          style={{
                                            background: 'white',
                                            border: `1px solid ${palette.PRIMARY50}`,
                                          }}
                                          onClick={() =>
                                            downloadFile(
                                              cgmpUploadFileUrl,
                                              `${form.getFieldValue('companyNameKo')}_제 ${
                                                field.name + 1
                                              }공장_cGMP`,
                                            )
                                          }
                                        >
                                          파일 다운로드
                                        </Button>
                                        <Typography.Text type="BODY_2" color="GRAY70">
                                          {isCgmp
                                            ? `cGMP`
                                            : `ISO22716 (유효기간: ${
                                                getFieldValue([
                                                  'factories',
                                                  field.name,
                                                  'iso22716ExpireDate',
                                                ]) || '-'
                                              })`}
                                        </Typography.Text>
                                      </Flex>
                                    )}
                                    {!isEdit && (factoryCgmp as File)?.name && (
                                      <Flex gap={8} align="center">
                                        <Typography.Text type="BODY_2" color="GRAY90">
                                          <ShortenText
                                            text={(factoryCgmp as File)?.name}
                                            width={116}
                                            endChar={3}
                                          />
                                        </Typography.Text>
                                      </Flex>
                                    )}
                                  </Flex>
                                </Form.Item>
                                {!isEdit && (factoryCgmp as File)?.name && (
                                  <Flex gap={16} style={{ marginTop: 38 }}>
                                    <CGMPRadioGroup
                                      value={getFieldValue('factories')[field.name].documentCode}
                                      defaultValue={'CGMP'}
                                      options={[
                                        { label: 'cGMP', value: 'CGMP' },
                                        {
                                          label: 'ISO22716',
                                          value: 'ISO22716',
                                        },
                                      ]}
                                      onChange={(e) =>
                                        handleChangeDocumentCode?.(e.target.value, field.name)
                                      }
                                    />
                                    <StyledFormItem
                                      label={'유효기간'}
                                      name={[field.name, 'expiryDate']}
                                      required={false}
                                      rules={generateFormRules({
                                        required:
                                          getFieldValue('factories')[field.name].documentCode !==
                                          'CGMP',
                                      })}
                                    >
                                      <DatePicker
                                        disabled={
                                          getFieldValue('factories')[field.name].documentCode !==
                                          'ISO22716'
                                        }
                                        placeholder="YYYY-MM-DD"
                                        style={{ width: 168 }}
                                        value={getFieldValue('factories')[field.name].expiryDate}
                                        onChange={(date) =>
                                          handleChangeExpiryDate?.(date, field.name)
                                        }
                                      />
                                    </StyledFormItem>
                                  </Flex>
                                )}
                              </Flex>
                              <Flex gap={16}>
                                {isEdit && (
                                  <Form.Item
                                    required
                                    label={`제 ${field.name + 1}공장 우편번호`}
                                    name={[field.name, 'zipCode']}
                                    rules={generateFormRules({
                                      minLength: 5,
                                      maxLength: 10,
                                      onlyNumber: true,
                                    })}
                                    style={{ flex: 1 }}
                                  >
                                    <Input disabled={isEdit} />
                                  </Form.Item>
                                )}
                                <Form.Item
                                  label={`제 ${field.name + 1}공장 전화번호`}
                                  name={[field.name, 'factoryTel']}
                                  style={{
                                    display: isEdit
                                      ? !getFieldValue(['factories', field.name, 'factoryTel'])
                                        ? 'none'
                                        : 'auto'
                                      : 'auto',
                                    flex:
                                      isEdit &&
                                      !getFieldValue(['factories', field.name, 'factoryFax'])
                                        ? 0.5
                                        : 1,
                                  }}
                                >
                                  <Input disabled={isEdit} placeholder="전화번호 입력" />
                                </Form.Item>
                                {!isEdit && (
                                  <Form.Item
                                    label={`제 ${field.name + 1}공장 팩스번호`}
                                    name={[field.name, 'factoryFax']}
                                    rules={[phoneRule]}
                                    style={{ flex: 1 }}
                                  >
                                    <Input disabled={isEdit} placeholder="숫자로 입력" />
                                  </Form.Item>
                                )}
                              </Flex>
                              {isEdit && getFieldValue(['factories', field.name, 'factoryFax']) && (
                                <Form.Item
                                  label={`제 ${field.name + 1}공장 팩스번호`}
                                  name={[field.name, 'factoryFax']}
                                  rules={[phoneRule]}
                                  style={{
                                    flex: 1,
                                    display: isEdit
                                      ? !getFieldValue(['factories', field.name, 'factoryFax'])
                                        ? 'none'
                                        : 'auto'
                                      : 'auto',
                                  }}
                                >
                                  <Input disabled={isEdit} placeholder="숫자로 입력" />
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                        </Flex>
                      </Fragment>
                    );
                  }}
                </Form.Item>
              );
            })}
            {!isEdit && (
              <Button
                type="dashed"
                block
                icon={<Icon name="plus" size={20} color="PRIMARY50" />}
                onClick={() => {
                  if (fields.length === 5) return message.warning('최대 5개까지 추가 가능합니다.');
                  add();
                }}
                style={{
                  marginBottom: 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 32,
                }}
              >
                공장 소재지 추가
              </Button>
            )}
          </>
        )}
      </Form.List>
    </Container>
  );
};

const Container = styled.div`
  .ant-form-item-no-colon {
    width: 100%;
  }
`;
const CGMPRadioGroup = styled(Radio.Group)`
  margin-top: 10px;
  .ant-radio-wrapper:first-child {
    margin-right: 20px;
  }
  .ant-radio-wrapper span.ant-radio + * {
    padding-inline-end: 0px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-row {
    flex-direction: row !important;
  }
  .ant-form-item-label {
    white-space: nowrap;
    text-align: end;
    padding: 0;
    label {
      height: 44px !important;
      font-size: 16px !important;
      margin-right: 8px !important;
    }
  }

  .ant-form-item-control {
    flex: 1 1 0;
    min-width: 0;
  }
`;

export default ManuCompanyForm;
