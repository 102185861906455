/**
 * TODO: 타입 작성 필요
 */
import axios from 'axios';
import JSZip from 'jszip';
import printJS from 'print-js';
import { pdfjs } from 'react-pdf';
import { message } from 'antd';

import client from './api/client';
import { getReactEnvVar } from 'lib/common';
import {
  CertificateInfoFormType,
  DocumentIndexCode,
  CertificateDocumentFileProps,
  docBlackList,
} from 'types/product';
import { APIResponse } from 'types/common';
import * as productApi from 'lib/api/product';

export const createFormData = (object: any, withoutArrayWrapper = false) => {
  const formData = new FormData();

  const appendFormData = (fieldName = '', value: any) => {
    if (value === undefined || value === null) {
      return;
    } else if (value instanceof FileList) {
      // HINT: 기존의 로직을 일단 그대로 가져옴, FileList는 []없이 같은 키 이름으로 value를 연속 append해도 괜찮은지 확인 필요
      formData.append(fieldName, value as any);
    } else if (value instanceof Array) {
      value.forEach((element, i) => {
        if (element instanceof File || element === null) {
          appendFormData(fieldName, element);
        } else if (withoutArrayWrapper) {
          appendFormData(`${fieldName}`, element);
        } else {
          appendFormData(`${fieldName}[${i}]`, element);
        }
      });
    } else if (value instanceof Object && !(value instanceof File)) {
      for (const [k, v] of Object.entries(value)) {
        appendFormData(`${fieldName}${fieldName.length > 0 ? '.' : ''}${k}`, v);
      }
    } else {
      // HINT: File 포함
      formData.append(fieldName, value);
    }
  };

  appendFormData('', object);
  return formData;
};

export const createFormDataForMarketing = (marketingFileForm: any) => {
  const formData = new FormData();

  for (const key in marketingFileForm) {
    const value = marketingFileForm[key];
    if (value instanceof Array) {
      //pages
      value.forEach((page, idx) => {
        for (const pageKey in page) {
          const pageValue = page[pageKey];
          if (pageValue instanceof Array) {
            //items
            pageValue.forEach((item, index) => {
              for (const itemKey in item) {
                const itemValue = item[itemKey];
                if (itemValue instanceof File) {
                  formData.append(`pages[${idx}].items[${index}].${itemKey}`, itemValue);
                } else if (typeof itemValue === 'undefined' || itemValue === null) {
                  continue;
                } else {
                  formData.set(`pages[${idx}].items[${index}].${itemKey}`, itemValue);
                }
              }
            });
          } else if (typeof pageValue === 'undefined' || pageValue === null) {
            continue;
          } else {
            formData.set(`pages[${idx}].${pageKey}`, pageValue);
          }
        }
      });
    } else if (typeof value === 'undefined' || value === null) {
      continue;
    } else {
      formData.set(key, value);
    }
  }
  return formData;
};

export const fileToBase64 = (file: any): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const base64ToFile = (base64String: any, filename: any) => {
  let arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
const blobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve((reader.result as any).split(',')[1]);
    reader.onerror = (error) => reject(error);
  });

export const toBinary = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const getResponseHeaderValueByKey = ({ target, key, seperator = ';' }: any) => {
  if (target) {
    const re = new RegExp(`(?<=${key}=).*(?=${seperator})?`, 'gi');
    const result = target.match(re);

    if (result && result.length > 0) {
      return result[0];
    }
  }

  return null;
};

export const createBlobFile = async (fileUrl: any) => {
  let response;

  if (fileUrl.startsWith('/files')) {
    response = await client.get(`${getReactEnvVar('SERVER_URL')}${fileUrl}`, {
      responseType: 'blob',
    });

    const filenameFromHeader = getResponseHeaderValueByKey({
      target: response.headers['content-disposition'],
      key: 'filename',
    });

    return {
      data: response.data,
      filenameFromHeader,
    };
  } else {
    response = await axios.get(fileUrl, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });
  }

  return { data: response.data };
};

export const downloadFile = async (fileUrl: any, name?: any) => {
  let a = document.createElement('a');

  if (fileUrl instanceof File) {
    a.href = URL.createObjectURL(fileUrl);
    a.download = name || fileUrl.name;
  } else {
    const { data: blobFile, filenameFromHeader } = await createBlobFile(fileUrl);
    let filename =
      name || filenameFromHeader || fileUrl.substring(fileUrl.lastIndexOf('/') + 1).split('?')[0];

    if (
      getFilenameExtension(fileUrl) &&
      getFilenameExtension(fileUrl) !== getFilenameExtension(filename)
    ) {
      filename += '.' + getFilenameExtension(fileUrl);
    }

    a.href = window.URL.createObjectURL(blobFile);
    a.download = decodeURIComponent(filename);
  }

  a.click();
};

const removeQsFromUrl = (url: any) => {
  const questionMarkPos = url.indexOf('?');
  return questionMarkPos === -1 ? url : url.substring(0, questionMarkPos);
};

export const downloadFiles = async (files: any, zipName = 'file.zip') => {
  const zip = new JSZip();
  for (const { name, url } of files) {
    const { data: blobData } = await createBlobFile(url);
    const binaryData: any = await blobToBase64(blobData);
    const extension = getFilenameExtension(url) || getFilenameExtension(url, '/');
    zip.file(`${name}${extension ? `.${extension}` : ''}`, binaryData, {
      base64: true,
    });
  }

  return new Promise((resolve) => {
    zip.generateAsync({ type: 'base64' }).then((content) => {
      const a = document.createElement('a');
      a.href = `data:application/zip;base64, ${content}`;
      a.download = zipName;
      a.click();
      resolve(undefined);
    });
  });
};

export const getFilenameFromUrl = async (url: any, excludeSuffix = false) => {
  if (url && url.startsWith('/files')) {
    const { filenameFromHeader } = await createBlobFile(url);
    let filename = filenameFromHeader;
    return filename;
  }
  if (!url || !url.includes('/') || !url.includes('.')) {
    throw new Error('Invalid URL');
  }

  let filename = decodeURIComponent(
    url.slice(url.lastIndexOf('/') + 1, excludeSuffix ? url.lastIndexOf('.') : undefined),
  );

  return filename.startsWith('/') ? filename.slice(1) : filename;
};

export const getReadableFileSize = (bytes: any, dp = 1) => {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = -1;

  do {
    bytes /= thresh;
    ++unitIndex;
  } while (
    Math.round(Math.abs(bytes) * 10 ** dp) / 10 ** dp >= thresh &&
    unitIndex < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[unitIndex];
};

export const getFilenameExtension = (url: any, seperator = '.') => {
  const splitUrl = removeQsFromUrl(url).split(seperator);

  return splitUrl.length === 1 ? '' : splitUrl[splitUrl.length - 1];
};

export const getFileTypeFromFilename = (filename: any) => {
  const mime = {
    svg: 'image/svg+xml',
    pdf: 'application/pdf',
    png: 'image/png',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    gif: 'image/gif',
  };
  const extension = getFilenameExtension(filename);
  return extension in mime ? (mime as any)[extension] : undefined;
};

export const urlToFile = async (url: any, filename: any, options?: any): Promise<File> => {
  let newUrl = url;

  if (options?.useOldS3Path) {
    newUrl = url.replace(/s3\.([^/]+)\.com\/([^/]+)/, '$2.s3.$1.com');
  }
  const res = await axios.get(newUrl, { responseType: 'blob' });
  return new File([res.data], filename, {
    type: getFileTypeFromFilename(filename),
  });
};

export const printFile = (file: any) => {
  if (typeof file === 'string' && file.startsWith('/files')) {
    (async () => {
      const response = await client.get(`${getReactEnvVar('SERVER_URL')}${file}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(response.data);
      printJS(decodeURIComponent(url));
      window.URL.revokeObjectURL(url);
    })();
  } else {
    printJS(decodeURIComponent(file));
  }
};

export const downloadFilesAsZip: (
  files: { name: string; url: string; zipName?: string; content?: string }[],
  fileName: string,
  preventDownload?: boolean,
) => Promise<string | undefined> = async (
  files: { name: string; url: string; zipName?: string; content?: string }[],
  fileName = 'file.zip',
  preventDownload = false,
) => {
  const zip = new JSZip();

  for (const { name, url, zipName, content } of files) {
    if (url === '') {
      // HINT: 빈 폴더를 만들고 싶을 경우.
      zip.folder(`${name}`);
    } else if (zipName && content) {
      zip.file(zipName, content, {
        base64: true,
      });
    } else {
      let response;

      if (url.startsWith('/files')) {
        response = await client.get(`${getReactEnvVar('SERVER_URL')}${url}`, {
          responseType: 'blob',
        });
      } else {
        response = await axios.get<Blob>(url, {
          responseType: 'blob',
        });
      }

      const binaryData: any = await blobToBase64(response.data);

      zip.file(
        `${name}${
          !name.includes('.')
            ? `.${getFilenameExtension(url) || getFilenameExtension(url, '/')}`
            : ''
        }`,
        binaryData,
        {
          base64: true,
        },
      );
    }
  }

  const content = await zip.generateAsync({ type: 'base64' });

  if (!preventDownload) {
    const a = document.createElement('a');
    a.href = `data:application/zip;base64, ${content}`;
    a.download = fileName;
    a.click();
  }

  return content;
};

// TODO: 적절한 파일명으로 이동 필요, 파일이 없어 임시로 현 파일에 배치
// CDRI Form 인증용 서류 API를 모두 호출하여, 필드의 cdriDocumentUrl에 넣어줌
export const generateCertificateDocumentMapWithDocumentUrl = async ({
  productId,
  countryId,
  certificateDocuments,
}: {
  productId: number;
  countryId?: number;
  certificateDocuments: CertificateDocumentFileProps[];
}): Promise<Map<string, CertificateDocumentFileProps>> => {
  const newCertificateDocumentMap = new Map<string, CertificateDocumentFileProps>();
  if (!certificateDocuments) return newCertificateDocumentMap;

  const filteredDocuments = certificateDocuments.filter(
    ({ documentName }) => !docBlackList.includes(documentName),
  );

  const asyncDownloadPromises = [];

  for (const document of filteredDocuments) {
    const newItem: CertificateDocumentFileProps = {
      ...document,
    };

    if (
      canCdriDocumentDownload({
        formType: document.formType,
        canGenerate: document?.generatableDocumentReport?.canGenerate,
      })
    ) {
      asyncDownloadPromises.push(
        new Promise<void>((resolve) => {
          client
            .get<APIResponse<{ documentUrl: string }>>(
              `/v1/products/${productId}/completion-document-files/${document.documentCode}`,
              {
                params: {
                  countryId,
                },
              },
            )
            .then(async (res) => {
              newItem.documentUrl = res.data.result.documentUrl;
              newItem.uploadFileUrls = [res.data.result.documentUrl];
              newItem.filenames = [await getFilenameFromUrl(res.data.result.documentUrl)];

              resolve();
            })
            .catch(() => {
              if (document.documentCode === DocumentIndexCode.ART) {
                // HINT: 파일 압축 실패시 Artwork api 개별 호출 후 url 삽입
                productApi
                  .getProductArtworks({
                    productId,
                    countryId,
                  })
                  .then((res) => {
                    newItem.documentUrls = res.data.result?.productArtworks
                      .filter(({ documentUrl }) => documentUrl !== null)
                      .map(({ documentUrl }) => documentUrl);
                    newItem.uploadFileUrls = res.data.result.productArtworks.map(
                      (item) => item.uploadFileUrl,
                    );
                    newItem.filenames = res.data.result.productArtworks.map(
                      ({ filename }) => filename,
                    );
                  });
                resolve();
              } else if (document.documentCode === DocumentIndexCode.PI) {
                // HINT: 파일 압축 실패시 리스트의 uploadFileUrl 삽입

                newItem.uploadFileUrl = document.uploadFileUrl;
                resolve();
              }
              resolve();
            });
        }),
      );
    }

    newCertificateDocumentMap.set(document.documentName, newItem);
  }

  await Promise.all(asyncDownloadPromises);

  return newCertificateDocumentMap;
};

// TODO: 적절한 파일명으로 이동 필요, 파일이 없어 임시로 현 파일에 배치
export const canCdriDocumentDownload = ({
  formType,
  canGenerate = false,
}: {
  formType: CertificateInfoFormType;
  canGenerate?: boolean;
}) => {
  return (formType === null || formType === CertificateInfoFormType.C) && canGenerate;
};

export const getPifDownloadFiles = async (
  certificateDocumentMap: Map<string, CertificateDocumentFileProps>,
) => {
  const files = getPifFiles(certificateDocumentMap);
  const filteredFiles = files.filter(
    (file) => typeof file?.url !== 'undefined' && file?.url !== null,
  ) as {
    name: string;
    url: string;
  }[];

  return filteredFiles;
};

export const getPifFiles = (certificateDocumentMap: Map<string, CertificateDocumentFileProps>) => {
  const artworkValue = certificateDocumentMap.get('Artwork');
  const cGMPValue = certificateDocumentMap.get('cGMP');
  const alValue = certificateDocumentMap.get('Allergens List');
  const ifraValue = certificateDocumentMap.get('IFRA Conformity Certificate');
  const thirdPartyValue = certificateDocumentMap.get('Third Party Test Report');
  // 3. Finished Product
  const coaUrl = certificateDocumentMap.get('Certificate of Analysis')?.documentUrl;
  const ctUrl = certificateDocumentMap.get('Challenge Test')?.documentUrl;
  const msdsUrl = certificateDocumentMap.get('Material Safety Data Sheet')?.documentUrl;
  const paUrl = certificateDocumentMap.get('Packing Attestation')?.documentUrl;
  const psUrl = certificateDocumentMap.get('Product Specifications')?.documentUrl;
  const stUrl = certificateDocumentMap.get('Stability Test')?.documentUrl;
  const hasFinishedProduct = coaUrl || ctUrl || msdsUrl || paUrl || psUrl || stUrl;

  const getExtensionFromMap = (key: string) =>
    getExtensionFromFileName(certificateDocumentMap.get(key)?.originalUploadFileName);

  return [
    // 1. Formula
    {
      name: '1. Formula/Product Formula Breakdown.pdf',
      url: certificateDocumentMap.get('Product Formula Breakdown')?.documentUrl,
    },
    {
      name: '1. Formula/Product Formula (Single).pdf',
      url: certificateDocumentMap.get('Product Formula')?.documentUrl,
    },
    {
      name: '1. Formula/Product Formula Breakdown.xlsx',
      url: certificateDocumentMap.get('Product Formula Breakdown')?.uploadFileUrl,
    },
    {
      name: '1. Formula/Product Formula (Single).xlsx',
      url: certificateDocumentMap.get('Product Formula')?.uploadFileUrl,
    },
    // 2. Ingredients
    {
      name: '2. Ingredients/1. MSDS.zip',
      url: certificateDocumentMap.get('Raw Material MSDS')?.uploadFileUrl,
    },
    {
      name: '2. Ingredients/2. COA.zip',
      url: certificateDocumentMap.get('Raw Material CoA')?.uploadFileUrl,
    },
    ...(alValue
      ? [
          {
            name: '2. Ingredients/3. Fragrance + Allergen list/Allergens List.pdf',
            url: alValue.documentUrl || alValue.uploadFileUrl,
          },
        ]
      : []),
    ...(ifraValue
      ? [
          {
            name: `2. Ingredients/3. Fragrance + Allergen list/${
              ifraValue.originalUploadFileName || 'IFRA Conformity Certificate.pdf'
            }`,
            url: ifraValue.uploadFileUrl,
          },
        ]
      : []),
    ...(!alValue?.uploadFileUrl && !ifraValue?.uploadFileUrl
      ? [
          {
            name: `2. Ingredients/3. Fragrance + Allergen list/`,
            url: '',
          },
        ]
      : []),
    // 3. Finished Product
    {
      name: '3. Finished Product/Certificate of Analysis.pdf',
      url: coaUrl,
    },
    {
      name: '3. Finished Product/Challenge Test.pdf',
      url: ctUrl,
    },
    {
      name: '3. Finished Product/Material Safety Data Sheet.pdf',
      url: msdsUrl,
    },
    {
      name: '3. Finished Product/Packing Attestation.pdf',
      url: paUrl,
    },
    {
      name: '3. Finished Product/Product Specifications.pdf',
      url: psUrl,
    },
    {
      name: '3. Finished Product/Stability Test.pdf',
      url: stUrl,
    },
    ...(!hasFinishedProduct
      ? [
          {
            name: '3. Finished Product/',
            url: '',
          },
        ]
      : []),
    // 4. Manufacturer Declarations
    ...(cGMPValue && cGMPValue.uploadFileUrl
      ? [
          {
            name: `4. Manufacturer Declarations/${
              cGMPValue.originalUploadFileName || getFilenameFromUrl(cGMPValue.uploadFileUrl)
            }`,
            url: cGMPValue.uploadFileUrl,
          },
        ]
      : []),
    {
      name: `4. Manufacturer Declarations/Manufacturing Process.pdf`,
      url: certificateDocumentMap.get('Manufacturing Process')?.documentUrl,
    },
    {
      name: `4. Manufacturer Declarations/Manufacturer's Declaration.pdf`,
      url: certificateDocumentMap.get(`Manufacturer's Declaration`)?.uploadFileUrl,
    },
    {
      name: `4. Manufacturer Declarations/Statement of Microbiological Purity.pdf`,
      url: certificateDocumentMap.get('Statement of Microbiological Purity')?.uploadFileUrl,
    },
    {
      name: `4. Manufacturer Declarations/Period on Markets.pdf`,
      url: certificateDocumentMap.get('Period on Markets')?.uploadFileUrl,
    },
    // 5. Labels & Images
    ...(artworkValue && artworkValue.filenames && artworkValue.filenames.length > 0
      ? artworkValue.filenames.map((name, index) => ({
          name: `5. Labels & Images/${name}`,
          url: artworkValue.uploadFileUrls?.[index],
        }))
      : [
          {
            name: '5. Labels & Images/',
            url: '',
          },
        ]),
    {
      name: `5. Labels & Images/Product Images.${getExtensionFromMap('Product Images')}`,
      url: certificateDocumentMap.get('Product Images')?.uploadFileUrl,
    },
    // 6. Substantiation
    ...(thirdPartyValue && thirdPartyValue.filenames && thirdPartyValue.filenames.length > 0
      ? thirdPartyValue.filenames.map((name, index) => ({
          name: `6. Substantiation/${name}`,
          url: thirdPartyValue.uploadFileUrls?.[index],
        }))
      : [
          {
            name: '6. Substantiation/',
            url: '',
          },
        ]),
    // 7. CPSR + CPNP Ref
    {
      name: '7. CPSR + CPNP Ref/',
      url: '',
    },
    // 8. Additional documents
    {
      name: '8. Additional documents/',
      url: '',
    },
  ];
};

export const getExtensionFromFileName = (fileName = '') => {
  if (!fileName?.indexOf || fileName.indexOf('.') === -1) return '';
  return fileName.split('.').pop();
};

export const getPDFPageCount = async (file: File): Promise<number> => {
  if (!file.name.toLowerCase().endsWith('.pdf')) return 0;

  try {
    const typedArray = await readFileAsArrayBuffer(file);

    // PDF 파일 로드 및 페이지 수 가져오기
    const pdf = await pdfjs.getDocument(typedArray).promise;
    return pdf.numPages;
  } catch (error) {
    message.warning('파일이 손상되어 열 수 없습니다. 다시 업로드해 주세요.');
    return 0;
  }
};

// 파일을 ArrayBuffer로 읽는 비동기 함수
const readFileAsArrayBuffer = (file: File): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      const result = e.target?.result;
      if (result) {
        resolve(new Uint8Array(result as ArrayBuffer));
      } else {
        reject(new Error('파일을 읽는 중 문제가 발생했습니다.'));
      }
    };

    fileReader.onerror = () => reject(new Error('파일을 읽는 중 오류가 발생했습니다.'));
    fileReader.readAsArrayBuffer(file);
  });
};
