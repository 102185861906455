import { useCallback, useMemo, useState } from 'react';
import DocumentPreviewModal from 'components/modal/product/DocumentPreviewModal';

const usePreviewModal = (options?: { title?: string; type?: 'primary' | 'secondary' }) => {
  const [url, setUrl] = useState<string[] | string | null>(null);
  const handleClose = useCallback(() => {
    setUrl(null);
  }, []);
  const PreviewModal = useCallback(
    ({
      footerVisible,
      documentName,
      previewTabs,
    }: {
      footerVisible?: boolean;
      documentName?: string;
      previewTabs?: {
        tabs: {
          key: string;
          value: string;
        }[];
      } | null;
    }) => {
      return (
        <DocumentPreviewModal
          previewTabs={previewTabs}
          documentUrls={url}
          documentName={documentName}
          footerVisible={footerVisible}
          onClose={handleClose}
          title={options?.title}
          type={options?.type}
        />
      );
    },
    [url, handleClose],
  );

  return useMemo(
    () => ({
      PreviewModal,
      setUrl,
    }),
    [PreviewModal],
  );
};

export default usePreviewModal;
