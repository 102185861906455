import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Statistic } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useCurrentProduct } from 'service/brand/product/product';
import StatisticCard from 'components/StatisticCard';
import { useDownloadCompletionDocumentFiles, useProductArtworkList } from 'service/product';
import { DocumentIndexCode, ProductCompletedDocument } from 'types/product';
import { downloadFile, getFilenameFromUrl } from 'lib/file';
import Icon from 'components/ui/Icon/Icon';
import Table from 'components/ui/Table/Table';
import { usePubCountries } from 'service/country';
import { Flex } from 'components/ui';
import usePreviewModal from 'hook/usePreviewModal';
import { useModal } from 'hook/useModal';
import { Typography } from 'components/system';

const CertificateDocumentsBlock = styled.div`
  transition: unset;
`;

const StyledButton = styled(Button)`
  max-width: 248px;
  min-width: 248px;
  transition: unset;
`;

const CertificateDocuments = ({
  documents,
  certificate,
  getLoading,
  downloadFilesLoading,
  downloadPIFLoading,
  onPreview,
  onDownload,
  onPIFDownload,
  onDocumentPreview,
  setPreviewTabs,
}: {
  documents: ProductCompletedDocument[];
  certificate: any;
  getLoading: boolean;
  downloadFilesLoading: boolean;
  downloadPIFLoading: boolean;
  onPreview: ({
    documentUrl,
    documentUrls,
    documentFilename,
    hasTabs,
  }: {
    documentUrl?: string;
    documentUrls?: string[];
    documentFilename?: string;
    hasTabs?: boolean;
  }) => void;
  onDownload: () => void;
  onPIFDownload: () => void;
  onDocumentPreview: ({
    type,
    documentCode,
    documentName,
  }: {
    type: 'download' | 'preview';
    documentCode: DocumentIndexCode;
    documentName: string;
  }) => void;
  setPreviewTabs: React.Dispatch<
    React.SetStateAction<{
      tabs: {
        key: string;
        value: string;
      }[];
    } | null>
  >;
}) => {
  const countries = usePubCountries();
  const currentProduct = useCurrentProduct() || {};
  const { getProductArtworkList } = useProductArtworkList();
  const { downloadCompletionDocumentFiles } = useDownloadCompletionDocumentFiles();
  const { PreviewModal, setUrl } = usePreviewModal();
  const { openAlertModal } = useModal();

  const [artworks, setArtworks] = useState<
    {
      uploadFileUrl: string;
      documentUrl: string;
      filename: string;
      targetCountryId: number;
    }[]
  >();

  const {
    certRequestNo,
    manufacturerNameEn,
    certRegisterNo,
    certFinYmd,
    certDocFilename,
    cpsrUploadFileUrl,
    cpsrGenerateFileUrl,
    estimateTarget: { productDetail, countryName, netWeight, netWeightUnit, countryId, productId },
    certTargetAppendCertification,
  } = certificate;

  useEffect(() => {
    getProductArtworkList(
      {
        productId: productDetail.productId,
        countryId: productDetail.countryId,
      },
      {
        onSuccess: (res) => {
          setArtworks(res?.data?.result?.productArtworks);
        },
      },
    );
  }, [productDetail]);

  const filteredDocuments = useMemo(() => {
    const arr: ProductCompletedDocument[] = [];

    documents.forEach((item) => {
      if (item.generatableDocumentReport.canGenerate || item.uploadFileUrl) {
        arr.push(item);
      } else if (item.documentCode === DocumentIndexCode.ART && artworks && artworks?.length > 0) {
        arr.push(item);
      }
    });
    return arr;
  }, [documents, artworks]);

  const columns: ColumnsType<ProductCompletedDocument> = useMemo(
    () => [
      {
        title: 'No.',
        align: 'center' as const,
        width: '20%',
        render: (_, __, index) => index + 1,
      },

      {
        title: '문서명',
        dataIndex: 'documentName',
      },
      {
        title: '업로드 서류',
        align: 'center' as const,
        width: '20%',
        render: (_, { uploadFileUrl, originalUploadFileName, documentCode, documentName }) => {
          if (uploadFileUrl) {
            return (
              <Icon
                name="download"
                size={20}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadFile(uploadFileUrl, originalUploadFileName);
                }}
                style={{ margin: '0 auto' }}
              />
            );
          } else if (documentCode === DocumentIndexCode.ART) {
            return (
              <Icon
                name="page"
                size={22}
                style={{ margin: '0 auto', cursor: 'pointer' }}
                onClick={() => {
                  onDocumentPreview?.({
                    type: 'download',
                    documentCode,
                    documentName,
                  });
                }}
              />
            );
          }
          return <></>;
        },
      },
      {
        title: '인증 서류',
        align: 'center',
        render: (_, { documentCode, generatableDocumentReport }) => {
          if (generatableDocumentReport?.canGenerate) {
            return (
              <Icon
                name="page"
                size={22}
                style={{ cursor: 'pointer', margin: '0 auto' }}
                onClick={() => {
                  downloadCompletionDocumentFiles(
                    {
                      productId,
                      countryId,
                      documentCode,
                    },
                    {
                      onSuccess: async (res) => {
                        if (res.data.errors.filter(({ code }) => code === '-99').length > 0) {
                          if (
                            documentCode === DocumentIndexCode.ART ||
                            documentCode === DocumentIndexCode.PI
                          )
                            openAlertModal({
                              width: 440,
                              noCancelButton: true,
                              content: (
                                <Typography.Text color="GRAY90" type="BODY_2">
                                  파일 크기가 5MB(유럽연합 인허가 플랫폼 기준)를
                                  <br />
                                  초과하여 파일을 확인할 수 없습니다.
                                  <br />
                                  PIF 다운로드 시에도 해당 파일은 포함되지 않습니다.
                                </Typography.Text>
                              ),

                              onOk: () => {
                                return;
                              },
                            });
                          return;
                        }

                        const url = res?.data?.result?.documentUrl || '';

                        if (url.includes('.zip')) {
                          downloadFile(url);
                          return;
                        }
                        setUrl(url);
                      },
                    },
                  );
                }}
              />
            );
          } else {
            return <></>;
          }
        },
      },
    ],
    [currentProduct],
  );

  const handlePreviewCpsrDocs = (filename: string) => {
    const isCpsrCountries = countryName === '영국' || countryName === '유럽연합';

    const isSameCpsrFile =
      certificate.cpsrUploadFileUrl === certTargetAppendCertification?.safetyReportUrl;

    onPreview({
      ...(isCpsrCountries &&
        isSameCpsrFile && { documentUrl: cpsrUploadFileUrl || cpsrGenerateFileUrl }),
      ...(!isCpsrCountries && { documentUrl: cpsrUploadFileUrl || cpsrGenerateFileUrl }),
      ...(isCpsrCountries &&
        !isSameCpsrFile && {
          documentUrls: cpsrUploadFileUrl
            ? [cpsrUploadFileUrl, certTargetAppendCertification?.safetyReportUrl]
            : [certTargetAppendCertification?.safetyReportUrl],
        }),
      documentFilename: filename,
    });

    if (
      isCpsrCountries &&
      !isSameCpsrFile &&
      !!certTargetAppendCertification &&
      cpsrUploadFileUrl
    ) {
      const anotherCountry = countryName === '영국' ? '유럽연합' : '영국';
      setPreviewTabs({
        tabs: [
          { key: countryName, value: countryName },
          { key: anotherCountry, value: anotherCountry },
        ],
      });
    }
  };

  return (
    <CertificateDocumentsBlock>
      <PreviewModal />
      <StatisticCard>
        <Row gutter={[16, 24]}>
          <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
            <Statistic title="인증 신청 번호" value={certRequestNo} />
          </Col>
          <Col sm={{ span: 10 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
            <Statistic
              title="제품명 (용량)"
              value={`${productDetail.productNameEn} ${
                netWeight ? `(${netWeight} ${netWeightUnit})` : '( - )'
              }`}
            />
          </Col>
          <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
            <Statistic title="제조사" value={manufacturerNameEn} />
          </Col>
          {!certTargetAppendCertification && (
            <>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="국가" value={countryName} />
              </Col>
              <Col sm={{ span: 10 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="인증 등록 번호" valueRender={() => certRegisterNo || '-'} />
              </Col>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="인증 등록 완료일" value={certFinYmd} />
              </Col>
            </>
          )}
        </Row>
      </StatisticCard>
      {certTargetAppendCertification && (
        <StatisticCard style={{ marginTop: 8 }}>
          <Row gutter={[16, 24]}>
            <>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="국가" value={countryName} />
              </Col>
              <Col sm={{ span: 10 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="인증 등록 번호" valueRender={() => certRegisterNo || '-'} />
              </Col>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic title="인증 등록 완료일" value={certFinYmd || '-'} />
              </Col>
            </>
          </Row>
          <Row gutter={[16, 24]} style={{ marginTop: 24 }}>
            <>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic
                  title="국가"
                  value={
                    countries?.find(
                      (item) => item.countryId === certTargetAppendCertification.country.countryId,
                    )?.countryNameKo || ''
                  }
                />
              </Col>
              <Col sm={{ span: 10 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic
                  title="인증 등록 번호"
                  valueRender={() => certTargetAppendCertification.certRegisterNo || '-'}
                />
              </Col>
              <Col sm={{ span: 7 }} xs={{ span: 12 }} style={{ textAlign: 'center' }}>
                <Statistic
                  title="인증 등록 완료일"
                  value={certTargetAppendCertification.certFinYmd}
                />
              </Col>
            </>
          </Row>
        </StatisticCard>
      )}
      <Flex
        justify="center"
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
        }}
        gap={16}
      >
        {((cpsrUploadFileUrl
          ? cpsrUploadFileUrl
          : certTargetAppendCertification?.safetyReportUrl) ||
          cpsrGenerateFileUrl) && (
          <StyledButton
            icon={<Icon name="clipboard" size={18} color="PRIMARY50" />}
            onClick={async () => {
              const filename = await getFilenameFromUrl(
                cpsrUploadFileUrl ||
                  cpsrGenerateFileUrl ||
                  certTargetAppendCertification?.safetyReportUrl,
              );
              setPreviewTabs(null);
              handlePreviewCpsrDocs(filename);
            }}
          >
            {countryName === '미국' ? 'US Safety Report 확인' : 'CPSR 확인'}
          </StyledButton>
        )}
        {(certificate?.certDocUrl || certTargetAppendCertification?.certDocUrl) && (
          <StyledButton
            icon={<Icon name="clipboard" size={18} color="PRIMARY50" />}
            onClick={() => {
              setPreviewTabs(null);

              if (certificate?.certDocUrl && certTargetAppendCertification) {
                const anotherCountry = countryName === '영국' ? '유럽연합' : '영국';
                setPreviewTabs({
                  tabs: [
                    { key: countryName, value: countryName },
                    { key: anotherCountry, value: anotherCountry },
                  ],
                });
                onPreview({
                  documentUrls: [certificate?.certDocUrl, certTargetAppendCertification.certDocUrl],
                });
                return;
              }
              onPreview({
                documentUrl: certificate?.certDocUrl || certTargetAppendCertification.certDocUrl,
                documentFilename: certDocFilename || certTargetAppendCertification?.certDocFilename,
              });
            }}
          >
            {countryName === '중국' ? '전자위생허가증 확인' : '등록증 확인'}
          </StyledButton>
        )}
        <StyledButton
          loading={downloadFilesLoading}
          icon={<Icon name="download" size={18} color="PRIMARY50" />}
          onClick={onDownload}
          style={{
            width: certTargetAppendCertification ? 195 : 200,
          }}
        >
          모든 서류 다운로드
        </StyledButton>
        {(countryName === '유럽연합' || countryName === '영국') && (
          <StyledButton
            loading={downloadPIFLoading}
            icon={<Icon name="download" size={18} color="PRIMARY50" />}
            onClick={onPIFDownload}
            style={{
              width: certTargetAppendCertification ? 195 : 200,
            }}
          >
            PIF 다운로드
          </StyledButton>
        )}
      </Flex>
      <Table
        columns={columns}
        rowKey={({ documentName }) => documentName}
        dataSource={filteredDocuments}
        pagination={false}
        loading={getLoading}
        style={{ marginTop: 40 }}
      />
    </CertificateDocumentsBlock>
  );
};

export default CertificateDocuments;
