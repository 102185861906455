import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Col, message, Row, Form } from 'antd';

import { messages } from 'lib/consts';
import { setCurrentProduct } from 'modules/product';
import ProductCGMP from 'components/product/ProductCGMP';
import UpdateLog from 'components/product/UpdateLog';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { DocumentCode } from 'types/product';
import { useProductDocStatus } from 'service/product';
import { updateProduct } from 'lib/api/product';
import { fixDocument } from 'modules/certificate';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import { useNavigate } from 'react-router-dom';
import { useMovePathname } from 'hook/useMovePathname';

const ProductCGMPContainer = () => {
  const navigate = useNavigate();
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const dispatch = useDispatch();
  const { movePathname } = useMovePathname();
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const { productDocStatus, addProdctDocStatus, addProdctDocStatusLoading } = useProductDocStatus({
    productId,
    countryId,
    documentCode: DocumentCode.CGMP,
  });
  const { currentProduct, readOnlyMode } = useSelector(
    ({ product, certificate }: any) => ({
      currentProduct: product.currentProduct,
      readOnlyMode: certificate.readOnlyMode,
    }),
    shallowEqual,
  );
  const updateMode = productDocStatus !== null;
  const [form] = Form.useForm();
  const [file, setFile] = useState<{ url: string; name: string } | null>(null);

  const handleDelete = () => {
    setFile(null);
  };
  const handleSubmit = ({
    isCGMP,
    ISOExpiredDate,
  }: {
    isCGMP: boolean;
    ISOExpiredDate: string;
  }) => {
    if (!file) {
      return message.warning(messages.REQUIRED_FILE);
    }
    if (!updateMode) {
      updateProduct({
        productId: currentProduct.productId,
        iso22716ExpireDate: isCGMP ? '' : ISOExpiredDate.replace(/-/g, ''),
      });
      addProdctDocStatus(
        {
          productId: currentProduct.productId,
          documentCode: DocumentCode.CGMP,
          documentFile: file,
          countryId,
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'cGMP',
              actionType: EGAActionType.REGISTER,
            });
            movePathname({ productId });
          },
        },
      );
    } else {
      const updateValues = {
        ...(!file.url && { file }),
        ...(ISOExpiredDate.replace(/-/g, '') !==
          currentProduct.productDetail.iso22716ExpireDate && {
          ISOExpiredDate: ISOExpiredDate.replace(/-/g, ''),
        }),
      };
      if (Object.keys(updateValues).length === 0) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      const updateProductCGMP = async () => {
        if (typeof updateValues.ISOExpiredDate !== 'undefined') {
          const res = await updateProduct({
            productId,
            ...(countryId && { countryId }),
            iso22716ExpireDate: updateValues.ISOExpiredDate,
          });

          dispatch(setCurrentProduct(res.data.result));
        }
        if (updateValues.file) {
          addProdctDocStatus({
            productId,
            documentCode: DocumentCode.CGMP,
            documentFile: updateValues.file,
            countryId,
          });
        }
      };

      updateProductCGMP().then(() => {
        const isMOD = productDocStatus?.status === 'MOD';
        sendEventToGA({
          documentName: 'cGMP',
          actionType: isMOD ? EGAActionType.MODIFY : EGAActionType.UPDATE,
        });
        if (isMOD) {
          dispatch(fixDocument(productDocStatus.productDocStatusId));
          message.success('보완 완료되었습니다.');
        } else {
          message.success('수정되었습니다.');
        }
        navigate(-1);
      });
    }
  };

  useEffect(() => {
    if (productDocStatus) {
      setFile({
        name: productDocStatus.filename,
        url: productDocStatus.uploadFileUrl,
      });
    }
  }, [productDocStatus]);

  useEffect(() => {
    form.setFieldsValue({
      isCGMP:
        currentProduct.productDetail.iso22716ExpireDate === '' ||
        currentProduct.productDetail.iso22716ExpireDate === null,
      ISOExpiredDate: currentProduct.productDetail.iso22716ExpireDate
        ? currentProduct.productDetail.iso22716ExpireDate.replace(
            /(\d{4})(\d{2})(\d{2})/,
            '$1-$2-$3',
          )
        : '',
    });
  }, []);

  return (
    <>
      <Row justify="end" gutter={8}>
        <Col>
          <ProductCorrectButton
            isFirstOpenChatModal={isFirstOpenChatModal}
            onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
            documentCode={DocumentCode.CGMP}
          />
        </Col>
        {updateMode && (
          <Col>
            <UpdateLog
              productId={productId}
              countryId={countryId}
              documentCode={DocumentCode.CGMP}
            />
          </Col>
        )}
      </Row>
      <ProductCGMP
        updateMode={updateMode}
        readOnlyMode={readOnlyMode}
        form={form}
        file={file}
        loading={addProdctDocStatusLoading}
        onUpload={setFile}
        onDelete={handleDelete}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ProductCGMPContainer;
