import { useRef, useState, useEffect } from 'react';
import styled, { css, CSSProperties } from 'styled-components';

const ShortenText = ({
  className,
  text,
  width,
  endChar,
  style,
}: {
  className?: string;
  text: string;
  width: number;
  endChar: number;
  style?: CSSProperties;
}) => {
  const [displayName, setDisplayName] = useState(text);
  const hiddenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hiddenElement = hiddenRef.current;

    if (hiddenElement) {
      const name = text.slice(0, text.lastIndexOf('.'));
      const extension = text.slice(text.lastIndexOf('.'));

      // `endChar`가 0일 때는 확장자만 남기도록 처리
      const endPart = endChar > 0 ? name.slice(-endChar) : '';
      let startPart = name.slice(0, name.length - endChar);
      let truncated =
        endChar > 0 ? `${startPart}...${endPart}${extension}` : `${name}...${extension}`;

      hiddenElement.textContent = text;

      if (hiddenElement.offsetWidth > width) {
        hiddenElement.textContent = truncated;

        while (hiddenElement.offsetWidth > width && startPart.length > 0) {
          startPart = startPart.slice(0, -1);
          truncated =
            endChar > 0 ? `${startPart}...${endPart}${extension}` : `${startPart}...${extension}`;
          hiddenElement.textContent = truncated;
        }

        setDisplayName(truncated);
      } else {
        setDisplayName(text);
      }
    }
  }, [text, width, endChar]);

  return (
    <Container className={className} width={width} style={style}>
      <HiddenText ref={hiddenRef}>{text}</HiddenText>
      {displayName}
    </Container>
  );
};

export default ShortenText;

const Container = styled.div<{ width: number }>`
  ${({ width }) => css`
    max-width: ${width}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const HiddenText = styled.div`
  position: absolute;
  white-space: nowrap;
  visibility: hidden;
`;
