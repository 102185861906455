import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

import { Typography } from 'components/system';
import { useManufacturers } from 'service/manufacturer/company';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import { useForceUpdate } from 'lib/hook';
import { scrollToFirstErrorOption } from 'lib/consts';
import { manuRegisterCompany, manuUpdateCompany } from 'modules/auth';
import { getFilenameFromUrl } from 'lib/file';
import { FormInitialData } from '../ManuCompanyForm';

interface UseManuRegisterFormProps {
  initialData?: FormInitialData;
  handleChangeTab?: (tab: 'company' | 'user') => void;
}

export interface ManuForm {
  companyNameKo: string;
  companyNameKoDirect: string;
  companyNameEn: string;
  bizNumber: string;
  addressKo: string;
  addressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  tel: string;
  fax: string;
  zipCode: string;
  homepageUrl: string;
  bizLicenseFile: File | { name: string; url: string };
  bizLicenseEnFile: File | { name: string; url: string };
  username: string;
  password: string;
  passwordConfirm: string;
  name: string;
  deptName: string;
  positionName: string;
  mobile: string;
  email: string;
  crcsbUrl: string;
  crcsbUrlName: string;
  factories: {
    documentCode?: string | null;
    addressKo: string;
    factoryTel: string;
    factoryFax: number;
    cgmpUploadFile: File;
    cgmpUploadFileUrl: string;
    iso22716ExpireDate: string;
    iso22716UploadFileUrl: string;
    file: { filename: string; url: string } | null;
    expiryDate: string;
  }[];
}

const useManuRegisterForm = ({ initialData, handleChangeTab }: UseManuRegisterFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [factorycGMP, setFactorycGMP] = useState<{
    [key: number]: File | { filename: string; url: string } | null;
  }>({});

  const [form] = Form.useForm<ManuForm>();
  const { data: allManufacturers } = useManufacturers();

  const { submitLoading, registerManuCompanySuccess } = useSelector(
    ({ auth, loading }) => ({
      submitLoading: loading['auth/MANU_REGISTER_COMPANY'] || loading['auth/MANU_UPDATE_COMPANY'],
      registerManuCompanySuccess: auth.registerManuCompanySuccess,
    }),
    shallowEqual,
  );

  const forceUpdate = useForceUpdate();
  const { openAlertModal } = useModal();

  const handleSelectSearch = (value: string): void => {
    setSearchValue(value.replace(/\s/gi, ''));
  };

  const handleUploadCgmpFile = (file: File, factoryKey: number) => {
    setFactorycGMP((prev) => {
      return { ...prev, [factoryKey]: file };
    });
  };

  const handleClickNext = (): void => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        handleChangeTab && handleChangeTab('user');
      })
      .catch((error: any) => {
        form.scrollToField(error.errorFields[0]?.name?.[0], scrollToFirstErrorOption);
      });
  };

  const handleClickPrev = (): void => {
    handleChangeTab && handleChangeTab('company');
  };

  const handleInitialData = async () => {
    if (!initialData) return;

    form.resetFields();
    const bizLicenseNameKo = initialData.company?.bizLicenseUrl
      ? await getFilenameFromUrl(initialData.company?.bizLicenseUrl)
      : '';
    const bizLicenseNameEn = initialData.company?.bizLicenseEnUrl
      ? await getFilenameFromUrl(initialData.company?.bizLicenseEnUrl)
      : '';
    const crcsbUrlName = initialData.company.crcsbUrl
      ? await getFilenameFromUrl(initialData.company.crcsbUrl)
      : '';

    form.setFieldsValue({
      companyNameKo: initialData.company.companyNameKo,
      companyNameKoDirect: initialData.company.companyNameKoDirect,
      companyNameEn: initialData.company.companyNameEn,
      addressKo: initialData.company.addressKo,
      addressEn: initialData.company.addressEn,
      bizNumber: initialData.company.bizNumber,
      ceoNameKo: initialData.company.ceoNameKo,
      ceoNameEn: initialData.company.ceoNameEn,
      tel: initialData.company.tel,
      fax: initialData.company.fax,
      zipCode: initialData.company.zipCode,
      homepageUrl: initialData.company.homepageUrl,
      bizLicenseFile: { name: bizLicenseNameKo, url: initialData.company.bizLicenseUrl },
      bizLicenseEnFile: { name: bizLicenseNameEn, url: initialData.company.bizLicenseEnUrl },
      username: initialData.user.username,
      name: initialData.user.name,
      deptName: initialData.user.deptName,
      positionName: initialData.user.positionName,
      mobile: initialData.user.mobile,
      email: initialData.user.email,
      crcsbUrl: initialData.company.crcsbUrl,
      crcsbUrlName: crcsbUrlName,
      factories: initialData.factories.map((item) => {
        return {
          addressKo: item.addressKo,
          factoryFax: item.fax,
          factoryTel: item.tel,
          cgmpUploadFile: item.cgmpUploadFile,
          cgmpUploadFileUrl: item.cgmpUploadFileUrl,
          iso22716ExpireDate: item.iso22716ExpireDate,
          iso22716UploadFileUrl: item.iso22716UploadFileUrl,
          seq: item.seq,
          documentCode: 'CGMP',
          zipCode: item.zipCode,
        };
      }),
    });

    const updateFactorycGMP = async () => {
      const updatedData = await Promise.all(
        initialData.factories.map(
          async ({ cgmpUploadFileUrl, cgmpUploadFile, iso22716UploadFileUrl }, i) => ({
            index: i,
            value: cgmpUploadFile
              ? cgmpUploadFile
              : {
                  filename:
                    cgmpUploadFileUrl || iso22716UploadFileUrl
                      ? await getFilenameFromUrl(cgmpUploadFileUrl || iso22716UploadFileUrl || '')
                      : '',
                  url: cgmpUploadFileUrl || iso22716UploadFileUrl || '',
                },
          }),
        ),
      );

      setFactorycGMP((prev) =>
        updatedData.reduce(
          (acc, { index, value }) => {
            acc[index] = value;
            return acc;
          },
          { ...prev },
        ),
      );
    };

    updateFactorycGMP();
  };

  const handleCreateManu = (formData: ManuForm): void => {
    const manufacturer = allManufacturers?.find(
      ({ manufacturerProfiles }) =>
        manufacturerProfiles.find(({ languageCode }) => languageCode === 'KO')?.companyName ===
        formData.companyNameKo,
    );

    const company = {
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(manufacturer?.manufacturerId && {
        manufacturerId: manufacturer.manufacturerId,
      }),
      bizNumber: formData.bizNumber,
      addressKo: formData.addressKo,
      addressEn: formData.addressEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }),
      bizLicenseFile: formData.bizLicenseFile,
      bizLicenseEnFile: formData.bizLicenseEnFile,
      companyType: 'MANUFACTURE',
    };

    const user = {
      isSuperUser: true,
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'MANUFACTURE',
    };

    const factories = formData.factories.map((fac, i) => ({
      seq: i + 1,
      addressKo: fac.addressKo,
      ...(fac.documentCode === 'CGMP'
        ? { cgmpUploadFile: factorycGMP[i] as File }
        : {
            iso22716UploadFile: factorycGMP[i] as File,
            iso22716ExpireDate: fac.expiryDate?.replace(/-/g, ''),
          }),
    }));

    dispatch(manuRegisterCompany({ company: { ...company, ...user }, factories }));
  };

  const handleEditManu = (formData: ManuForm): void => {
    if (!initialData) return;

    const company = {
      companyId: initialData.company.companyId,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }), // TODO: homapegeUrl -> 빈 문자열, 키 삭제, null 경우 삭제 불가능 API 확인 필요
    };

    const user = {
      userId: initialData.user.userId,
      username: formData.bizNumber.replace(/-/g, ''),
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
    };

    const updateFormData = { user, companyId: company.companyId };

    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>정말로 수정하시겠습니까?</Typography.Text>
      ),
      onOk: () => {
        dispatch(manuUpdateCompany(updateFormData));
      },
    });
  };

  const handleChangeDocumentCode = (documentCode: string, factoryKey: number) => {
    const currFormData = form.getFieldsValue();

    form.setFieldsValue({
      ...currFormData,
      factories: (form.getFieldValue('factories') as FormInitialData['factories']).map((fac, i) =>
        factoryKey === i
          ? {
              ...fac,
              documentCode,
            }
          : fac,
      ),
    });
  };

  const handleChangeExpiryDate = (date: string, factoryKey: number) => {
    const currFormData = form.getFieldsValue();

    form.setFieldsValue({
      ...currFormData,
      factories: (form.getFieldValue('factories') as FormInitialData['factories']).map((fac, i) =>
        factoryKey === i
          ? {
              ...fac,
              expiryDate: date,
            }
          : fac,
      ),
    });
  };

  useEffect(() => {
    handleInitialData();
  }, [initialData]);

  useEffect(() => {
    if (registerManuCompanySuccess) {
      navigate(path.register.success);
    }
  }, [registerManuCompanySuccess]);

  return {
    form,
    searchValue,
    submitLoading,
    factorycGMP,
    allManufacturers,
    forceUpdate,
    handleSelectSearch,
    handleChangeExpiryDate,
    handleChangeDocumentCode,
    handleUploadCgmpFile,
    handleClickNext,
    handleClickPrev,
    handleInitialData,
    handleCreateManu,
    handleEditManu,
  };
};

export default useManuRegisterForm;
