import { message } from 'antd';
import produce from 'immer';
import { createAction, handleActions } from 'redux-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import * as authApi from 'lib/api/auth';
import { clearToken, setToken } from 'lib/api/client';
import * as companyApi from 'lib/api/company';
import { checkMobile } from 'lib/common';
import { createFormData } from 'lib/file';
import { createRequestActionTypes, createRequestSaga } from 'lib/saga';
import Storage from 'lib/storage';
import { finishLoading, startLoading } from 'modules/loading';
import { UserType } from 'types/auth';

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');
const [LOGIN_BY_TOKEN, LOGIN_BY_TOKEN_SUCCESS] = createRequestActionTypes('auth/LOGIN_BY_TOKEN');
const SET_USER = 'auth/SET_USER';
const INITIALIZE_LOGIN = 'auth/INITIALIZE_LOGIN';
const LOGOUT = 'auth/LOGOUT';
const SET_LOGO_TERMS_AGREED = 'auth/SET_LOGO_TERMS_AGREED';
const [REGISTER_COMPANY, REGISTER_COMPANY_SUCCESS, REGISTER_COMPANY_FAILURE] =
  createRequestActionTypes('auth/REGISTER_COMPANY');
const [MANU_REGISTER_COMPANY, MANU_REGISTER_COMPANY_SUCCESS, MANU_REGISTER_COMPANY_FAILURE] =
  createRequestActionTypes('auth/MANU_REGISTER_COMPANY');
const [UPDATE_COMPANY, UPDATE_COMPANY_SUCCESS, UPDATE_COMPANY_FAILURE] =
  createRequestActionTypes('auth/UPDATE_COMPANY');
const [MANU_UPDATE_COMPANY, MANU_UPDATE_COMPANY_SUCCESS, MANU_UPDATE_COMPANY_FAILURE] =
  createRequestActionTypes('auth/MANU_UPDATE_COMPANY');
const [REGISTER_USER, REGISTER_USER_SUCCESS, REGISTER_USER_FAILURE] =
  createRequestActionTypes('auth/REGISTER_USER');
const [UPDATE_USER, UPDATE_USER_SUCCESS] = createRequestActionTypes('auth/UPDATE_USER');
const [DELETE_USER, DELETE_USER_SUCCESS] = createRequestActionTypes('auth/DELETE_USER');
const [RESET_PASSWORD, RESET_PASSWORD_SUCCESS] = createRequestActionTypes('auth/RESET_PASSWORD');
const [FIND_PASSWORD, FIND_PASSWORD_SUCCESS] = createRequestActionTypes('auth/FIND_PASSWORD');
const TOGGLE_FIND_PASSWORD_MODAL = 'auth/TOGGLE_FIND_PASSWORD_MODAL';
const [GET_MANAGERS, GET_MANAGERS_SUCCESS] = createRequestActionTypes('auth/GET_MANAGERS');
const SET_ADD_MANAGER_BOX_VISIBLE = 'auth/SET_ADD_MANAGER_BOX_VISIBLE';
const SET_CHANGE_PASSWORD_MODAL_VISIBLE = 'auth/SET_CHANGE_PASSWORD_MODAL_VISIBLE';
const [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS] = createRequestActionTypes('auth/CHANGE_PASSWORD');
const [FIRST_CHANGE_PASSWORD, FIRST_CHANGE_PASSWORD_SUCCESS] = createRequestActionTypes(
  'auth/FIRST_CHANGE_PASSWORD',
);

// login
export const login = createAction(LOGIN);
const loginSaga = function* ({ payload: { username, password, userType, fromModal } }) {
  yield put(startLoading(LOGIN));
  try {
    const {
      data: { result: user },
    } = yield call(authApi.login, {
      username,
      password,
      userType,
    });
    if ((user.userType === UserType.MANUFACTURE || user.isMaterial) && checkMobile()) {
      message.warning('PC 브라우저를 이용해 주세요.');
    } else {
      Storage.setItem('userType', userType);
      yield put({
        type: LOGIN_SUCCESS,
        payload: user,
        fromModal,
      });
    }
  } catch (error) {
    yield put({
      type: LOGIN_FAILURE,
      payload: error,
      error: true,
    });
  }
  yield put(finishLoading(LOGIN));
};

const loginSuccessSaga = function* ({ payload: user, fromModal }) {
  yield put(startLoading(LOGIN_SUCCESS));

  const getCompanyResponse = yield call(companyApi.getCompany, user.companyId);
  yield put({
    type: 'company/GET_COMPANY_SUCCESS',
    payload: getCompanyResponse.data.result,
  });
  if (getCompanyResponse.data.result.companyType === 'MANUFACTURE') {
    const getFactoriesResponse = yield call(companyApi.getFactories, user.companyId);
    yield put({
      type: 'company/GET_FACTORIES_SUCCESS',
      payload: getFactoriesResponse.data.result,
    });
  }

  yield put(finishLoading(LOGIN_SUCCESS));
};
export const loginByToken = createAction(LOGIN_BY_TOKEN);
const loginByTokenSaga = createRequestSaga(LOGIN_BY_TOKEN, authApi.reissueToken);
export const setUser = createAction(SET_USER);
export const initializeLogin = createAction(INITIALIZE_LOGIN);
export const findPassword = createAction(FIND_PASSWORD);
const findPasswordSaga = createRequestSaga(FIND_PASSWORD, authApi.findPassword);
const findPasswordSuccessSaga = function* () {
  message.success('임시비밀번호가 이메일로 전송되었습니다.');
};
export const togglePasswordModal = createAction(TOGGLE_FIND_PASSWORD_MODAL);
export const logout = createAction(LOGOUT);
const logoutSaga = function* ({ payload }) {
  yield put({ type: 'company/INITIALIZE_COMPANY' });
  yield put({ type: 'company/INITIALIZE_FACTORY' });
  clearToken(true);
  if (!payload) {
    message.success('로그아웃 되었습니다.');
  }
};

// register
export const setLogoTermsAgreed = createAction(SET_LOGO_TERMS_AGREED);
export const registerUser = createAction(REGISTER_USER);
const registerUserSaga = function* (action) {
  yield put(startLoading(REGISTER_USER));
  try {
    yield call(authApi.registerUser, action.payload);
    yield put({
      type: REGISTER_USER_SUCCESS,
    });
    const companyId = yield select((state) => state.auth.user.companyId);
    yield put(getManagers(companyId));
  } catch (error) {
    yield put({
      type: REGISTER_USER_FAILURE,
      payload: error,
      error: true,
    });
  }
  yield put(finishLoading(REGISTER_USER));
};
const registerUserSuccessSaga = function* () {
  message.success('담당자가 추가되었습니다');
};
export const deleteUser = createAction(DELETE_USER);
export const deleteUserSaga = createRequestSaga(DELETE_USER, authApi.deleteUser);
export const deleteUserSuccessSaga = function* () {
  message.success('담당자가 삭제되었습니다.');
  const companyId = yield select((state) => state.auth.user.companyId);
  yield put(getManagers(companyId));
};

// update
export const updateUser = createAction(UPDATE_USER);
const updateUserSaga = createRequestSaga(UPDATE_USER, authApi.updateUser);
const updateUserSuccessSaga = function* () {
  message.success('담당자가 수정되었습니다.');
};
export const resetPassword = createAction(RESET_PASSWORD);
const resetPasswordSaga = createRequestSaga(RESET_PASSWORD, authApi.resetPassword);
const resetPasswordSuccessSaga = function* () {
  message.success("담당자의 비밀번호가 '1234'로 초기화되었습니다.");
};

export const registerCompany = createAction(REGISTER_COMPANY);
const registerCompanySaga = function* ({ payload }) {
  const { isUseOfLogoAgreed } = yield select(({ auth }) => auth.register);
  yield put(startLoading(REGISTER_COMPANY));
  yield put({
    type: REGISTER_COMPANY_SUCCESS,
    payload: false,
  });
  try {
    yield call(companyApi.registerCompany, {
      ...payload,
      isServiceTermsAgreed: true,
      isPrivacyTermsAgreed: true,
      isUseOfLogoAgreed,
    });
    yield put({
      type: REGISTER_COMPANY_SUCCESS,
      payload: true,
    });
  } catch (error) {
    yield put({
      type: REGISTER_COMPANY_FAILURE,
      payload: error,
      error: true,
    });
  }
  yield put(finishLoading(REGISTER_COMPANY));
};
export const manuRegisterCompany = createAction(MANU_REGISTER_COMPANY);
const manuRegisterCompanySaga = function* ({ payload }) {
  const { company, factories } = payload;
  const { isUseOfLogoAgreed } = yield select(({ auth }) => auth.register);
  yield put(startLoading(MANU_REGISTER_COMPANY));
  yield put({
    type: MANU_REGISTER_COMPANY_SUCCESS,
    payload: false,
  });
  try {
    const { data } = yield call(companyApi.registerCompany, {
      ...company,
      isServiceTermsAgreed: true,
      isPrivacyTermsAgreed: true,
      isUseOfLogoAgreed,
    });
    for (const factory of factories) {
      yield call(
        companyApi.registerFactory,
        createFormData({
          ...factory,
          companyId: data.result.companyId,
          manufacturerCompanyId: data.result.manufacturerCompanyId,
        }),
      );
    }
    yield put({
      type: MANU_REGISTER_COMPANY_SUCCESS,
      payload: true,
    });
  } catch (error) {
    yield put({
      type: MANU_REGISTER_COMPANY_FAILURE,
      payload: error,
    });
  }
  yield put(finishLoading(MANU_REGISTER_COMPANY));
};
export const updateCompany = createAction(UPDATE_COMPANY);
const updateCompanySaga = function* ({ payload }) {
  const { company, user, companyId } = payload;
  yield put(startLoading(UPDATE_COMPANY));
  try {
    if (company) {
      yield call(companyApi.updateCompany, company);
    }
    yield call(authApi.updateUser, user);
    const getUserResponse = yield call(authApi.getUser);
    yield put(setUser(getUserResponse.data.result));
    if (company) {
      const getCompanyResponse = yield call(companyApi.getCompany, companyId);
      yield put({
        type: 'company/GET_COMPANY_SUCCESS',
        payload: getCompanyResponse.data.result,
      });
    }
    yield put({
      type: UPDATE_COMPANY_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_FAILURE,
      payload: error,
      error: true,
    });
  }
  yield put(finishLoading(UPDATE_COMPANY));
};
const updateCompanySuccessSaga = function* () {
  message.success('회원정보가 수정되었습니다.');
};
export const manuUpdateCompany = createAction(MANU_UPDATE_COMPANY);
const manuUpdateCompanySaga = function* ({ payload }) {
  const { company, user, factories, companyId } = payload;
  yield put(startLoading(MANU_UPDATE_COMPANY));
  try {
    if (company) {
      yield call(companyApi.updateCompany, company);
    }
    yield call(authApi.updateUser, user);
    for (const factory of factories) {
      switch (factory.type) {
        case 'ADD': {
          yield call(companyApi.registerFactory, factory);
          break;
        }
        case 'MODIFY': {
          yield call(companyApi.updateFactory, factory);
          break;
        }
        default:
      }
    }
    const getUserResponse = yield call(authApi.getUser);
    yield put(setUser(getUserResponse.data.result));
    const getFactoriesResponse = yield call(companyApi.getFactories, companyId);
    yield put({
      type: 'company/GET_FACTORIES_SUCCESS',
      payload: getFactoriesResponse.data.result,
    });
    if (company) {
      const getCompanyResponse = yield call(companyApi.getCompany, companyId);
      yield put({
        type: 'company/GET_COMPANY_SUCCESS',
        payload: getCompanyResponse.data.result,
      });
    }
    yield put({
      type: MANU_UPDATE_COMPANY_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: MANU_UPDATE_COMPANY_FAILURE,
      payload: error,
      error: true,
    });
  }
  yield put(finishLoading(MANU_UPDATE_COMPANY));
};

// manager
export const setAddManagerBoxVisible = createAction(SET_ADD_MANAGER_BOX_VISIBLE);

// etc
export const getManagers = createAction(GET_MANAGERS);
const getManagersSaga = createRequestSaga(GET_MANAGERS, authApi.getManagers);

export const setChangePasswordModalVisible = createAction(SET_CHANGE_PASSWORD_MODAL_VISIBLE);

export const changePassword = createAction(CHANGE_PASSWORD);
const changePasswordSaga = createRequestSaga(CHANGE_PASSWORD, authApi.changePassword);
const changePasswordSuccessSaga = function* () {
  message.success('비밀번호가 변경되었습니다.');
  yield put(setChangePasswordModalVisible(false));
};

export const firstChangePassword = createAction(FIRST_CHANGE_PASSWORD);
const firstChangePasswordSaga = createRequestSaga(FIRST_CHANGE_PASSWORD, authApi.changePassword);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest([LOGIN_SUCCESS, LOGIN_BY_TOKEN_SUCCESS], loginSuccessSaga);
  yield takeLatest(REGISTER_COMPANY, registerCompanySaga);
  yield takeLatest(MANU_REGISTER_COMPANY, manuRegisterCompanySaga);
  yield takeLatest(UPDATE_COMPANY, updateCompanySaga);
  yield takeLatest(MANU_UPDATE_COMPANY, manuUpdateCompanySaga);
  yield takeLatest([UPDATE_COMPANY_SUCCESS, MANU_UPDATE_COMPANY_SUCCESS], updateCompanySuccessSaga);
  yield takeLatest(REGISTER_USER, registerUserSaga);
  yield takeLatest(REGISTER_USER_SUCCESS, registerUserSuccessSaga);
  yield takeLatest(UPDATE_USER, updateUserSaga);
  yield takeLatest(UPDATE_USER_SUCCESS, updateUserSuccessSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(DELETE_USER_SUCCESS, deleteUserSuccessSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(RESET_PASSWORD_SUCCESS, resetPasswordSuccessSaga);
  yield takeLatest(FIND_PASSWORD, findPasswordSaga);
  yield takeLatest(FIND_PASSWORD_SUCCESS, findPasswordSuccessSaga);
  yield takeLatest(LOGIN_BY_TOKEN, loginByTokenSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(GET_MANAGERS, getManagersSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(CHANGE_PASSWORD_SUCCESS, changePasswordSuccessSaga);
  yield takeLatest(FIRST_CHANGE_PASSWORD, firstChangePasswordSaga);
}

const initialState = {
  user: null,
  login: {
    error: null,
    failCount: 0,
    isShowFindPasswordModal: false,
  },
  register: {
    isUseOfLogoAgreed: false,
  },
  auth: null,
  authError: null,
  registerCompanySuccess: false,
  registerManuCompanySuccess: false,
  tokenError: null,
  managers: [],
  changePasswordModalVisible: false,
};

export default handleActions(
  {
    [REGISTER_COMPANY_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.authError = error;
      }),
    [REGISTER_COMPANY_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.registerCompanySuccess = payload;
      }),
    [MANU_REGISTER_COMPANY_SUCCESS]: (state, { payload }) =>
      produce(state, (draft) => {
        draft.registerManuCompanySuccess = payload;
      }),
    [LOGIN]: (state) =>
      produce(state, (draft) => {
        draft.login.error = null;
      }),
    [LOGIN_SUCCESS]: (state, { payload: user }) => {
      setToken(user.token, user.refreshToken);
      return produce(state, (draft) => {
        draft.user = user;
      });
    },
    [LOGIN_FAILURE]: (state, { payload: error }) =>
      produce(state, (draft) => {
        draft.login.error = error;
        draft.login.failCount += 1;
      }),
    [LOGIN_BY_TOKEN_SUCCESS]: (state, { payload: user }) => {
      setToken(user.token, user.refreshToken);
      return produce(state, (draft) => {
        draft.user = user;
      });
    },
    [SET_USER]: (state, { payload: user }) =>
      produce(state, (draft) => {
        draft.user = user;
      }),
    [LOGOUT]: (state) =>
      produce(state, (draft) => {
        draft.user = null;
      }),
    [INITIALIZE_LOGIN]: (state) =>
      produce(state, (draft) => {
        draft.login = initialState.login;
      }),
    [FIND_PASSWORD_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.login.isShowFindPasswordModal = false;
      }),
    [TOGGLE_FIND_PASSWORD_MODAL]: (state) =>
      produce(state, (draft) => {
        draft.login.isShowFindPasswordModal = !draft.login.isShowFindPasswordModal;
      }),
    [SET_LOGO_TERMS_AGREED]: (state, { payload: isUseOfLogoAgreed }) =>
      produce(state, (draft) => {
        draft.register.isUseOfLogoAgreed = isUseOfLogoAgreed;
      }),
    [GET_MANAGERS_SUCCESS]: (state, { payload: managers }) =>
      produce(state, (draft) => {
        draft.managers = managers.filter(
          ({ userId, status }) => userId !== state.user.userId && status !== 'DEL',
        );
      }),
    [SET_CHANGE_PASSWORD_MODAL_VISIBLE]: (state, { payload: visible }) =>
      produce(state, (draft) => {
        draft.changePasswordModalVisible = visible;
      }),
    [FIRST_CHANGE_PASSWORD_SUCCESS]: (state) =>
      produce(state, (draft) => {
        draft.user.isResetPassword = false;
      }),
  },
  initialState,
);
