import { Button, Modal } from 'antd';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import artworkScreeningDocumentSVG from 'asset/svg/artwork_screening_document.svg';
import artworkScreeningCompleteAnimation from 'asset/lottie/CompleteAnimation.json';
import { useEffect, useState } from 'react';

export interface ArtworkProgressBarModalProps {
  type: 'IN_PROGRESS' | 'COMPLETE';
  visible: boolean;
  screeningStatus: string;
  canCancel?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  onClose?: () => void;
}

const ArtworkProgressBarModal: React.FC<ArtworkProgressBarModalProps> = ({
  type,
  visible,
  screeningStatus,
  canCancel = false,
  onCancel = () => null,
  onOk = () => null,
  onClose = () => null,
}) => {
  return (
    <StyledModal
      open={visible}
      destroyOnClose
      footer={null}
      centered
      width={400}
      onCancel={onCancel}
      onClose={onClose}
      maskClosable={false}
    >
      {type === 'IN_PROGRESS' && (
        <ContentInProgress
          screeningStatus={screeningStatus}
          onCancel={onCancel}
          canCancel={canCancel}
        />
      )}
      {type === 'COMPLETE' && <ContentComplete onOk={onOk} />}
    </StyledModal>
  );
};
export default ArtworkProgressBarModal;
/**
 * 💡 HINT: Helper Function
 * ====================
 */
export const delayFunc = (delay: number, callback: () => void) => {
  const timer = setTimeout(() => {
    callback();
    clearTimeout(timer);
  }, delay);
};
/**
 * 💡 HINT: Local Sub Components
 * ====================
 */
const ContentInProgress: React.FC<
  Pick<ArtworkProgressBarModalProps, 'screeningStatus' | 'onCancel' | 'canCancel'>
> = ({ screeningStatus, canCancel, onCancel }) => {
  const TICK = 2;
  const FPS = 2000;
  const MAX_PROGRESS = 100;
  const limitAutoProgress = MAX_PROGRESS - TICK;
  const isComplete = screeningStatus === 'COMPLETE';
  const [progress, setProgress] = useState<number>(0);

  const [cancelDisabled, setCancelDisabled] = useState<boolean>(false);

  useEffect(() => {
    setCancelDisabled(!canCancel);
  }, [canCancel]);

  useEffect(() => {
    if (!isComplete && progress < MAX_PROGRESS) {
      const interval = setInterval(
        () => setProgress((prev) => Math.min(prev + TICK, limitAutoProgress)),
        FPS,
      );
      return () => clearInterval(interval);
    }
  }, [progress, isComplete]);

  useEffect(() => {
    if (isComplete) setProgress(MAX_PROGRESS);
  }, [isComplete]);

  return (
    <Flex dir="column" justify="center" align="center">
      <img
        width={72}
        height={68}
        src={artworkScreeningDocumentSVG}
        alt="artwork screening Document"
      />
      <Typography.Text type="BODY_1" color="GRAY90" semiBold gutter={{ top: 16 }}>
        업로드 파일을 스크리닝중입니다.
      </Typography.Text>
      <Typography.Text type="BODY_1" color="PRIMARY50" semiBold gutter={{ top: 24, bottom: 4 }}>
        {progress.toFixed(0)}%
      </Typography.Text>
      <ProgressBar value={progress} max={MAX_PROGRESS} />
      <Typography.Text
        type="BODY_2"
        color="SLATE_GRAY70"
        underline
        gutter={{ top: 20 }}
        onClick={cancelDisabled ? () => null : onCancel}
      >
        스크리닝 취소
      </Typography.Text>
    </Flex>
  );
};
const ContentComplete: React.FC<Pick<ArtworkProgressBarModalProps, 'onOk'>> = ({ onOk }) => {
  return (
    <Flex dir="column" justify="center" align="center">
      <Lottie
        width={88}
        height={88}
        options={{
          loop: true,
          autoplay: true,
          animationData: artworkScreeningCompleteAnimation,
          rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
        }}
      />
      <Typography.Text type="TITLE_2" color="GRAY90" semiBold gutter={{ top: 16 }}>
        스크리닝이 완료되었습니다.
      </Typography.Text>
      <Typography.Text type="BODY_2" color="GRAY70" gutter={{ top: 4 }}>
        아래 버튼을 눌러 스크리닝 결과를 확인해 보세요.
      </Typography.Text>
      <Button htmlType="button" type="primary" onClick={onOk} style={{ marginTop: '24px' }}>
        결과 화면으로 가기
      </Button>
    </Flex>
  );
};

/**
 * 💡 HINT: styled components
 * =======================
 */
const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 48px 24px;
    text-align: center;
  }
  .ant-modal-close {
    display: none;
  }
`;
const ProgressBar = styled.progress`
  width: 280px;
  height: 12px;
  border-radius: 30px;
  appearance: none;

  &::-webkit-progress-bar {
    background: ${palette.SLATE_GRAY30};
    border-radius: 30px;
  }
  &::-webkit-progress-value {
    background: ${palette.PRIMARY50};
    border-radius: 30px;
    transition: width 0.5s ease-in-out;
  }
  &::-moz-progress-bar {
    background: ${palette.PRIMARY50};
    border-radius: 30px;
    transition: width 0.5s ease-in-out;
  }
`;
